import React, { useCallback, useEffect, useMemo, useState } from "react";
import BreadCrumb from "Common/BreadCrumb";
import CountUp from "react-countup";
import { toast } from "react-hot-toast";
import { useAuthStore } from "store/useAuthStore";
import * as XLSX from "xlsx";

// icons
import {
  ArrowDown,
  CircleDollarSign,
  Download,
  Edit,
  Loader,
  Minus,
  MoreHorizontal,
  Plus,
  Printer,
  Search,
  Trash,
} from "lucide-react";
import { Link } from "react-router-dom";
import TableContainer from "Common/TableContainer";
import Modal from "Common/Components/Modal";

// Formik
import * as Yup from "yup";
import { useFormik, FieldArray, Field, Formik } from "formik";
import { ToastContainer } from "react-toastify";
import axios from "axios";
import moment from "moment";
import { paymentMethods, paymentStatus } from "Common/constants/payment";
import { Dropdown } from "Common/Components/Dropdown";
import { capitalizeFirstLetter } from "helpers/utils";

const Sales = () => {
  const { user } = useAuthStore();
  const [creatingSale, setCreatingSale] = useState<boolean>(false);
  const [userList, setUserList] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [dataList, setDataList] = useState<any>([]);
  const [data, setData] = useState<any>([]);
  const [carList, setCarList] = useState<any>([]);
  const [images, setImages] = useState<any>([]);
  const [show, setShow] = useState<boolean>(false);
  const [initialVehicle, setInitialVehicle] = useState<string>("");
  const [initialDriver, setInitialDriver] = useState<string>("");
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const [editId, setEditId] = useState<string>("");

  useEffect(() => {
    handleGetCars();
    handleFetchUsers();
    handleGetAllSales();
  }, [initialVehicle, initialDriver]);

  const handleGetAllSales = async () => {
    try {
      setLoading(true);
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URI}/crm/sale/all`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        },
      );
      setDataList(res.data);
      setData(res.data);
      setData((prev: any) => {
        return prev.map((item: any) => {
          return {
            ...item,
            saleDate: moment(item.saleDate).format("DD MMMM, YYYY"),
          };
        });
      });
      setData((prev: any) => {
        return prev.map((item: any) => {
          return {
            ...item,
            driverName: item.driver ? item.driver.user.name : "N/A",
          };
        });
      });
      setDataList((prev: any) => {
        return prev.map((item: any) => {
          return {
            ...item,
            driverName: item.driver ? item.driver.user.name : "N/A",
          };
        });
      });
      setDataList((prev: any) => {
        return prev.map((item: any) => {
          return {
            ...item,
            saleDate: moment(item.saleDate).format("DD MMMM, YYYY"),
          };
        });
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleGetCars = async () => {
    try {
      setLoading(true);
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URI}/crm/car/all`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        },
      );
      setCarList(res.data);
      setInitialVehicle(res.data[0]._id);
      setCarList((prev: any) => {
        return prev.map((item: any) => {
          return {
            ...item,
            createdAt: moment(item.createdAt).format("DD MMMM, YYYY"),
          };
        });
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleFetchUsers = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URI}/crm/driver/all`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        },
      );
      console.log(res.data);

      setUserList(res.data);
      setUserList((prev: any) => {
        return prev.map((item: any) => {
          return {
            ...item,
            createdAt: moment(item.createdAt).format("DD MMMM, YYYY"),
          };
        });
      });
      setInitialDriver(res.data[0]._id);
    } catch (error) {
      console.log(error);
    }
  };

  const deleteSale = async (id: string) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_BASE_URI}/crm/sale/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        },
      );
      handleGetAllSales();
      toast.success("Sale deleted successfully!");
    } catch (error) {
      console.log(error);
    }
  };

  function handlePrint() {
    window.print();
  }

  const filterDate: any = useFormik({
    initialValues: {
      fromDate: "",
      toDate: "",
    },
    validationSchema: Yup.object({
      toDate: Yup.string().required("Please Enter To Date"),
      fromDate: Yup.string().required("Please Enter From Date"),
    }),

    onSubmit: async (values) => {
      const { fromDate, toDate } = values;
      console.log(fromDate, toDate);
    },
  });

  const handleFilterDate = () => {
    const { fromDate, toDate } = filterDate.values;
    if (!fromDate || !toDate) {
      return toast.error("Please select both from and to date");
    }
    const from = new Date(fromDate);
    const to = new Date(toDate);
    if (from > to) {
      return toast.error("From date cannot be greater than to date");
    }
    const filteredData = dataList.filter((item: any) => {
      return (
        new Date(item.saleDate) >= new Date(fromDate) &&
        new Date(item.saleDate) <= new Date(toDate)
      );
    });
    setData(filteredData);
  };

  // validation
  const validation: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      saleDate: "",
      saleAmount: 0,
      vehicle: initialVehicle,
      paymentMethod: paymentMethods[0].name,
      paymentStatus: paymentStatus[0].name,
      invoiceNumber: "",
      service: "n/a",
      driver: initialDriver,
      saleBy: "office",
      receivedFrom: "n/a",
      receivedBy: "n/a",
      sales: [
        {
          amount: '0',
          saleDate: "",
          service: "",
          notes: "",
        }
      ]
      // reason: "",
    },
    validationSchema: Yup.object({
      receivedFrom: Yup.string().optional(),
      receivedBy: Yup.string().optional(),
      // reason: Yup.string().required("Please Enter Reason"),
      saleBy: Yup.string().required("Please select a creator"),
      vehicle: Yup.string().required("Please Enter Vehicle"),
      paymentMethod: Yup.string().optional(),
      paymentStatus: Yup.string().optional(),
      invoiceNumber: Yup.string().optional(),
      driver: Yup.string().optional(),
      sales: Yup.array().of(
        Yup.object().shape({
          amount: Yup.string().required("Amount is required"),
          saleDate: Yup.string().required("Date is required"),
          service: Yup.string().required("Service is required"),
          note: Yup.string().optional(),
        })
      )
    }),

    onSubmit: async (values) => {
      const newData = {
        ...values,
        vehicle: values.saleBy != "office" ? values.vehicle : null,
        driver: values.saleBy != "office" ? values.driver : null,
      };
      if (values.saleBy === "driver") {
        if (values.driver === "") {
          return toast.error("Please select a driver");
        }
      }
      setCreatingSale(true);
      try {
        await axios.post(
          `${process.env.REACT_APP_BASE_URI}/crm/sale`,
          {
            ...newData,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          },
        );
        handleGetAllSales();
        toast.success("Sale made successfully!");
        toggle();
        validation.resetForm();
        setImages([]);
      } catch (error: any) {
        if (!error.response) {
          return toast.error("Network error. Please try again.");
        }
        if (typeof error.response.data === "string") {
          return toast.error(error.response.data);
        }
      } finally {
        setCreatingSale(false);
      }
    },
  });

  const toggle = useCallback(() => {
    if (show) {
      setShow(false);
    } else {
      setShow(true);
      validation.resetForm();
    }
  }, [show, validation]);

  const filterData = (time: string) => {
    const today = new Date();
    const lastWeek = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
    const lastMonth = new Date(today.getTime() - 30 * 24 * 60 * 60 * 1000);
    const lastYear = new Date(today.getTime() - 365 * 24 * 60 * 60 * 1000);
    switch (time) {
      case "day": {
        setData(dataList.filter((item: any) => {
          return new Date(item.saleDate) >= today;
        }));

        break;
      }
      case "week":
        setData(dataList.filter((item: any) => {
          return new Date(item.saleDate) >= lastWeek;
        }));
        break;
      case "month":
        setData(dataList.filter((item: any) => {
          return new Date(item.saleDate) >= lastMonth;
        }));
        break;
      case "year":
        setData(dataList.filter((item: any) => {
          return new Date(item.saleDate) >= lastYear;
        }));
        break;
      default:
        setData(dataList);
    }
  };
  // Search Data
  const filterSearchData = (e: any) => {
    const search = e.target.value;
    const keysToSearch = [
      "saleAmount",
      "saleDate",
      "paymentStatus",
      "paymentMethod",
      "_id",
      "invoiceNumber",
      "driverName",
      "customerName",
      "customerContact",
      "customerAddress",
    ];
    const filteredData = dataList.filter((item: any) => {
      return keysToSearch.some((key) => {
        return String(item[key]).toLowerCase().includes(search.toLowerCase());
      });
    });
    setData(filteredData);
  };

  const [activeTab, setActiveTab] = useState("1");

  useEffect(() => {
    setDataList(data);
  }, []);

  const toggleTab = (tab: any, type: any) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      let filteredDeposits = dataList;
      if (type !== "all") {
        filteredDeposits = dataList.filter(
          (sale: any) => sale.paymentStatus === type,
        );
      }
      setData(filteredDeposits);
    }
  };

  const handleUpdateStatus = async (id: string, status: string) => {
    try {
      await axios.put(
        `${process.env.REACT_APP_BASE_URI}/crm/sale/${id}`, validation.values,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        },
      );
      handleGetAllSales();
      setShowEdit(false);
      setEditId("");
      toast.success("Sale updated successfully!");
    } catch (error) {
      console.log(error);
    }
  };

  const toggleEdit = useCallback((purchase: any) => {
    if (showEdit) {
      setShowEdit(false);
      setEditId("");
    } else {
      setShowEdit(true);
      setEditId(purchase._id);
      console.log("purchase", purchase);
      validation.setFieldValue("paymentStatus", purchase.paymentStatus);
      validation.setFieldValue("saleAmount", purchase.saleAmount.toString());
      validation.setFieldValue("service", purchase.service);
      validation.setFieldValue("saleDate", purchase.saleDate);
      validation.setFieldValue("saleBy", purchase.saleBy);
      validation.setFieldValue("vehicle", purchase.vehicle);
      validation.setFieldValue("driver", purchase.saleBy.toLowerCase() === 'driver' ? purchase.driver._id : "");
    }
  }, [showEdit, validation]);


  // columns
  const Status = ({ item }: any) => {
    switch (item) {
      case "Received":
        return (
          <span className="delivery_status px-2.5 py-0.5 text-xs inline-block font-medium rounded border bg-green-100 border-green-200 text-green-500 dark:bg-green-500/20 dark:border-green-500/20">
            {item}
          </span>
        );
      case "Not Received":
        return (
          <span className="delivery_status px-2.5 py-0.5 text-xs inline-block font-medium rounded border bg-red-100 border-red-200 text-red-500 dark:bg-red-500/20 dark:border-red-500/20">
            {item}
          </span>
        );
      default:
        return (
          <span className="delivery_status px-2.5 py-0.5 text-xs inline-block font-medium rounded border bg-green-100 border-green-200 text-green-500 dark:bg-green-500/20 dark:border-green-500/20">
            {item}
          </span>
        );
    }
  };

  const downloadSalesInExcel = async () => {
    const data = dataList.map((item: any) => {
      return {
        ID: item._id,
        InvoiceNumber: item.invoiceNumber,
        Amount: item.saleAmount,
        PaymentStatus: item.paymentStatus,
        PaymentMethod: item.paymentMethod,
        DriverId: item.driver != null ? item.driver._id : "N/A",
        Driver: item.driver != null ? item.driver.user.name : "N/A",
        Date: item.saleDate,
      };
    });
    const fileName = "Sales";
    const exportType = "xls";
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, `${fileName}.${exportType}`);
  };

  const columns = useMemo(
    () => [
      {
        id: "checkAll",
      },
      {
        header: "ID",
        accessorKey: "_id",
        enableColumnFilter: false,
        enableSorting: false,
        cell: (cell: any) => (
          <>
            <Link
              to="#!"
              className="transition-all duration-150 ease-linear order_id text-custom-500 hover:text-custom-600"
            >
              {cell.getValue()}
            </Link>
          </>
        ),
      },
      {
        header: "Amount",
        accessorKey: "saleAmount",
        enableColumnFilter: false,
      },
      {
        header: "Service",
        accessorKey: "service",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <p>
            {cell.row.original.service != null
              ? capitalizeFirstLetter(cell.row.original.service)
              : "N/A"}
          </p>
        ),
      },
      {
        header: "Note",
        accessorKey: "notes",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <p>
            {cell.row.original.notes !== ""
              ? capitalizeFirstLetter(cell.row.original.notes)
              : "N/A"}
          </p>
        ),
      },
      {
        header: "Driver",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <p>
            {cell.row.original.driver != null
              ? cell.row.original.driver.user.name
              : "N/A"}
          </p>
        ),
      },
      {
        header: "Date",
        accessorKey: "saleDate",
        enableColumnFilter: false,
      },
      {
        header: "Action",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell: any) => (
          <Dropdown className="relative">
            <Dropdown.Trigger
              id="orderAction1"
              data-bs-toggle="dropdown"
              className="flex items-center justify-center size-[30px] p-0 text-slate-500 btn bg-slate-100 hover:text-white hover:bg-slate-600 focus:text-white focus:bg-slate-600 focus:ring focus:ring-slate-100 active:text-white active:bg-slate-600 active:ring active:ring-slate-100 dark:bg-slate-500/20 dark:text-slate-400 dark:hover:bg-slate-500 dark:hover:text-white dark:focus:bg-slate-500 dark:focus:text-white dark:active:bg-slate-500 dark:active:text-white dark:ring-slate-400/20"
            >
              <MoreHorizontal className="size-3" />
            </Dropdown.Trigger>
            <Dropdown.Content
              placement={cell.row.index ? "top-end" : "right-end"}
              className="absolute z-50 py-2 mt-1 ltr:text-left rtl:text-right list-none bg-white rounded-md shadow-md min-w-[10rem] dark:bg-zink-600"
              aria-labelledby="orderAction1"
            >
              <li
                onClick={() => toggleEdit(cell.row.original)}
                className="cursor-pointer"
              >
                <div className="block px-4 py-1.5 text-base transition-all duration-200 ease-linear text-slate-600 hover:bg-slate-100 hover:text-slate-500 focus:bg-slate-100 focus:text-slate-500 dark:text-zink-100 dark:hover:bg-zink-500 dark:hover:text-zink-200 dark:focus:bg-zink-500 dark:focus:text-zink-200">
                  <Edit className="inline-block size-3 ltr:mr-1 rtl:ml-1" />
                  {" "}
                  <span className="align-middle">Update status</span>
                </div>
              </li>
              <li
                onClick={() => deleteSale(cell.row.original._id)}
              >
                <p className="block cursor-pointer px-4 py-1.5 text-base transition-all duration-200 ease-linear text-red-500 hover:bg-red-100 hover:text-red-500 focus:bg-slate-100 focus:text-slate-500 dark:text-zink-100 dark:hover:bg-zink-500 dark:hover:text-zink-200 dark:focus:bg-zink-500 dark:focus:text-zink-200">
                  <Trash className="inline-block size-3 ltr:mr-1 rtl:ml-1" />
                  {" "}
                  <span className="align-middle">Delete</span>
                </p>
              </li>
            </Dropdown.Content>
          </Dropdown>
        ),
      },
    ],
    [],
  );

  const addItem = () => {
    validation.setFieldValue('sales', [
      ...validation.values.sales,
      { amount: '0', saleDate: '', service: '' }
    ]);
  };

  const removeItem = (index: any) => {
    const sales = validation.values.sales.slice();
    sales.splice(index, 1);
    validation.setFieldValue('sales', sales);
  };

  return (
    <React.Fragment>
      {loading && (
        <div className="fixed inset-0 z-50 flex items-center justify-center w-full h-full bg-white bg-opacity-90 dark:bg-zink-900 dark:bg-opacity-90">
          <Loader className="size-10 text-custom-500 animate-spin" />
        </div>
      )}
      <BreadCrumb title="All Sales" pageTitle="Sales" />
      <ToastContainer closeButton={false} limit={1} />
      <div className="grid grid-cols-1 gap-x-5 md:grid-cols-2 2xl:grid-cols-10">
        <div className="2xl:col-span-2 2xl:row-span-1">
          <div className="card">
            <div className="flex items-center gap-3 card-body">
              <div className="flex items-center justify-center size-12 text-yellow-500 rounded-md text-15 bg-yellow-50 dark:bg-yellow-500/20 shrink-0">
                <Loader />
              </div>
              <div className="grow">
                <h5 className="mb-1 text-16">
                  <CountUp
                    // add all the sale amounts where payment status is not received

                    end={data.filter((item: any) =>
                      item.paymentStatus === "Not Received"
                    ).reduce(
                      (acc: any, sale: any) => acc + sale.saleAmount,
                      0,
                    )}
                    // end={data.filter((sale: any) =>
                    //   sale.paymentStatus === "Pending"
                    // ).length}
                    separator=","
                    className="counter-value"
                  />
                </h5>
                <p className="text-slate-500 dark:text-zink-200">
                  Total Not Received
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="2xl:col-span-2 2xl:row-span-1">
          <div className="card">
            <div className="flex items-center gap-3 card-body">
              <div className="flex items-center justify-center size-12 text-green-500 rounded-md text-15 bg-green-50 dark:bg-green-500/20 shrink-0">
                <ArrowDown />
              </div>
              <div className="grow">
                <h5 className="mb-1 text-16">
                  <CountUp
                    end={data.filter((item: any) =>
                      item.paymentStatus === "Received"
                    ).reduce(
                      (acc: any, sale: any) => acc + sale.saleAmount,
                      0,
                    )}
                    separator=","
                    className="counter-value"
                  />
                </h5>
                <p className="text-slate-500 dark:text-zink-200">
                  Total Paid
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="card" id="ordersTable">
        <div className="card-body">
          <div className="flex gap-2">
            <div className="ml-auto flex flex-row gap-2">
              <button
                onClick={handlePrint}
                type="button"
                className="bg-white border-dashed text-custom-500 btn border-custom-500 hover:text-custom-500 hover:bg-custom-50 hover:border-custom-600 focus:text-custom-600 focus:bg-custom-50 focus:border-custom-600 active:text-custom-600 active:bg-custom-50 active:border-custom-600 dark:bg-zink-700 dark:ring-custom-400/20 dark:hover:bg-custom-800/20 dark:focus:bg-custom-800/20 dark:active:bg-custom-800/20"
              >
                <Printer className="inline-block size-4" />{" "}
                <span className="align-middle">Print page</span>
              </button>
              <button
                onClick={downloadSalesInExcel}
                type="button"
                className="bg-white border-dashed text-custom-500 btn border-custom-500 hover:text-custom-500 hover:bg-custom-50 hover:border-custom-600 focus:text-custom-600 focus:bg-custom-50 focus:border-custom-600 active:text-custom-600 active:bg-custom-50 active:border-custom-600 dark:bg-zink-700 dark:ring-custom-400/20 dark:hover:bg-custom-800/20 dark:focus:bg-custom-800/20 dark:active:bg-custom-800/20"
              >
                <Download className="inline-block size-4" />{" "}
                <span className="align-middle">Export</span>
              </button>
            </div>
          </div>
          <div className="flex items-center">
            <h6 className="text-15 grow">Sales List</h6>
          </div>
          <div className="!py-3.5 card-body border-y border-dashed border-slate-200 dark:border-zink-500">
            <form action="#!">
              <div className="grid grid-cols-1 gap-5 xl:grid-cols-12">
                <div className="relative xl:col-span-2">
                  <input
                    type="text"
                    className="ltr:pl-8 rtl:pr-8 search form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                    placeholder="Search for name, email, phone number etc..."
                    autoComplete="off"
                    onChange={(e) => filterSearchData(e)}
                  />
                  <Search className="inline-block size-4 absolute ltr:left-2.5 rtl:right-2.5 top-2.5 text-slate-500 dark:text-zink-200 fill-slate-100 dark:fill-zink-600" />
                </div>

                <div className="xl:col-span-2">
                  <select
                    className="form-select text-slate-400 border-slate-200 dark:border-zink-500 dark:text-zink-200"
                    onChange={(e) => filterData(e.target.value)}
                  >
                    <option value="all" selected>All time</option>
                    <option value="day">Day</option>
                    <option value="week">Week</option>
                    <option value="month">Month</option>
                    <option value="year">Year</option>
                  </select>
                </div>

                <div className="xl:col-span-6 text-slate-400 flex flex-row gap-4 w-full">
                  <div className="w-1/2 flex flex-row gap-2 items-center">
                    <label
                      htmlFor="fromDate"
                      className="inline-block mb-2 text-base font-medium"
                    >
                      From
                    </label>
                    <input
                      type="date"
                      id="fromDate"
                      className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                      placeholder="12345678"
                      name="fromDate"
                      onChange={filterDate.handleChange}
                      value={filterDate.values.fromDate || ""}
                    />
                  </div>

                  <div className="w-1/2 flex flex-row gap-2 items-center">
                    <label
                      htmlFor="toDate"
                      className="inline-block mb-2 text-base font-medium"
                    >
                      To
                    </label>
                    <input
                      type="date"
                      id="toDate"
                      className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                      placeholder="12345678"
                      name="toDate"
                      onChange={filterDate.handleChange}
                      value={filterDate.values.toDate || ""}
                    />
                  </div>

                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      handleFilterDate();
                    }}
                    className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
                  >
                    {"Filter "}
                  </button>
                </div>
                <div className="xl:col-span-3 xl:col-start-10">
                  <div className="flex gap-2 xl:justify-end">
                    <div>
                      <button
                        type="button"
                        className="bg-white border-dashed text-custom-500 btn border-custom-500 hover:text-custom-500 hover:bg-custom-50 hover:border-custom-600 focus:text-custom-600 focus:bg-custom-50 focus:border-custom-600 active:text-custom-600 active:bg-custom-50 active:border-custom-600 dark:bg-zink-700 dark:ring-custom-400/20 dark:hover:bg-custom-800/20 dark:focus:bg-custom-800/20 dark:active:bg-custom-800/20"
                        onClick={toggle}
                      >
                        <span className="align-middle">Add sale</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <ul className="flex flex-wrap w-full mt-5 text-sm font-medium text-center text-gray-500 nav-tabs">
            <li className={`group ${activeTab === "1" && "active"}`}>
              <Link
                to="#"
                data-tab-toggle
                data-target=""
                className="inline-block px-4 py-1.5 text-base transition-all duration-300 ease-linear rounded-md text-slate-500 dark:text-zink-200 border border-transparent group-[.active]:bg-custom-500 group-[.active]:text-white dark:group-[.active]:text-white hover:text-custom-500 dark:hover:text-custom-500 active:text-custom-500 dark:active:text-custom-500 -mb-[1px]"
                onClick={() => {
                  toggleTab("1", "all");
                }}
              >
                <CircleDollarSign className="inline-block size-4 ltr:mr-1 rtl:ml-1" />
                {" "}
                <span className="align-middle">All Sales</span>
              </Link>
            </li>
            <li className={`group ${activeTab === "2" && "active"}`}>
              <Link
                to="#"
                data-tab-toggle
                data-target="all"
                className="inline-block px-4 py-1.5 text-base transition-all duration-300 ease-linear rounded-md text-slate-500 dark:text-zink-200 border border-transparent group-[.active]:bg-custom-500 group-[.active]:text-white dark:group-[.active]:text-white hover:text-custom-500 dark:hover:text-custom-500 active:text-custom-500 dark:active:text-custom-500 -mb-[1px]"
                onClick={() => {
                  toggleTab("2", "Received");
                }}
              >
                <Loader className="inline-block size-4 ltr:mr-1 rtl:ml-1" />
                {" "}
                <span className="align-middle">Received</span>
              </Link>
            </li>
            <li className={`group ${activeTab === "3" && "active"}`}>
              <Link
                to="#"
                data-tab-toggle
                data-target=""
                className="inline-block px-4 py-1.5 text-base transition-all duration-300 ease-linear rounded-md text-slate-500 dark:text-zink-200 border border-transparent group-[.active]:bg-custom-500 group-[.active]:text-white dark:group-[.active]:text-white hover:text-custom-500 dark:hover:text-custom-500 active:text-custom-500 dark:active:text-custom-500 -mb-[1px]"
                onClick={() => {
                  toggleTab("3", "Not Received");
                }}
              >
                <ArrowDown className="inline-block size-4 ltr:mr-1 rtl:ml-1" />
                {" "}
                <span className="align-middle">Not Received</span>
              </Link>
            </li>
          </ul>

          {data && data.length > 0
            ? (
              <TableContainer
                isPagination={true}
                columns={columns || []}
                data={data || []}
                customPageSize={10}
                divclassName="mt-5 overflow-x-auto"
                tableclassName="w-full whitespace-nowrap"
                theadclassName="ltr:text-left rtl:text-right bg-slate-100 dark:bg-zink-600"
                thclassName="px-3.5 py-2.5 font-semibold text-slate-500 border-b border-slate-200 dark:border-zink-500 dark:text-zink-200"
                tdclassName="px-3.5 py-2.5 border-y border-slate-200 dark:border-zink-500"
                PaginationClassName="flex flex-col items-center mt-5 md:flex-row"
              />
            )
            : (
              <div className="noresult">
                <div className="py-6 text-center">
                  <Search className="size-6 mx-auto text-sky-500 fill-sky-100 dark:sky-500/20" />
                  <h5 className="mt-2 mb-1">Sorry! No Result Found</h5>
                  <p className="mb-0 text-slate-500 dark:text-zink-200">
                    We've searched all sales, but we did not find any sales for
                    your search.
                  </p>
                </div>
              </div>
            )}
        </div>
      </div>

      {/* Order Modal */}

      <Modal
        show={show}
        onHide={toggle}
        modal-center="true"
        className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
        dialogClassName="w-screen md:w-[30rem] bg-white shadow rounded-md dark:bg-zink-600"
      >
        <Modal.Header
          className="flex items-center justify-between p-4 border-b dark:border-zink-500"
          closeButtonClass="transition-all duration-200 ease-linear text-slate-400 hover:text-red-500"
        >
          <Modal.Title className="text-16">{"Create a Sale"}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit(e)
            }}
          >
            <div className="grid grid-cols-1 gap-4 xl:grid-cols-12">
              <div className="xl:col-span-12">
                <label
                  htmlFor="saleBy"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Sale By
                </label>
                <select
                  name="saleBy"
                  id="saleBy"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  value={validation.values.saleBy || ""}
                  onChange={validation.handleChange}
                  required
                >
                  <option value={"office"}>
                    Office
                  </option>
                  <option value={"driver"}>
                    Driver
                  </option>
                </select>
              </div>
              {validation.values.saleBy.toLowerCase() === "driver" &&
                (
                  <div className="xl:col-span-12">
                    <label
                      htmlFor="driver"
                      className="inline-block mb-2 text-base font-medium"
                    >
                      Driver
                    </label>
                    <select
                      name="driver"
                      id="driver"
                      className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                      value={validation.values.driver || ""}
                      onChange={validation.handleChange}
                      required
                    >
                      {userList.map((driver) =>
                        // @ts-ignore
                        <option value={driver._id}>{driver.user.name}</option>
                      )}
                    </select>
                  </div>
                )}
              {/* <div className="xl:col-span-12">
                    <label
                      htmlFor="saleAmount"
                      className="inline-block mb-2 text-base font-medium"
                    >
                      Amount
                    </label>
                    <input
                      type="text"
                      id="saleAmount"
                      className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                      placeholder="12345678"
                      name="saleAmount"
                      onChange={validation.handleChange}
                      value={validation.values.saleAmount || ""}
                    />
                    {validation.touched.saleAmount && validation.errors.saleAmount
                      ? (
                        <p className="text-red-400">
                          {validation.errors.saleAmount}
                        </p>
                      )
                      : null}
                  </div> */}
              <div className="xl:col-span-12">
                {validation.values.sales.length > 0 &&
                  validation.values.sales.map((item: any, index: any) => (
                    <>
                      <div className="row " key={index}>
                        <div className="col">
                          <label htmlFor={`sales.${index}.amount`}>Amount</label>
                          <input
                            type="text"
                            id={`sales.${index}.amount`}
                            className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                            placeholder="12345678"
                            name={`sales.${index}.amount`}
                            onChange={validation.handleChange}
                            value={item.amount || ""}
                          />
                          {validation.touched.sales?.[index]?.amount && validation.errors.sales?.[index]?.amount ? (
                            <p className="text-red-400">{validation.errors.sales[index].amount}</p>
                          ) : null}
                        </div>
                        <div className="col">
                          <label htmlFor={`sales.${index}.date`}>Date</label>
                          <input
                            type="date"
                            id={`sales.${index}.saleDate`}
                            className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                            name={`sales.${index}.saleDate`}
                            onChange={validation.handleChange}
                            value={item.saleDate || ""}
                          />

                          {validation.touched.sales?.[index]?.saleDate && validation.errors.sales?.[index]?.saleDate ? (
                            <p className="text-red-400">{validation.errors.sales[index].saleDate}</p>
                          ) : null}
                        </div>
                        <div className="xl:col-span-12">
                          <label
                            htmlFor={`sales.${index}.service`}
                            className="inline-block mb-2 text-base font-medium"
                          >
                            Service
                          </label>
                          <select
                            id={`sales.${index}.service`}
                            name={`sales.${index}.service`}
                            className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                            onChange={validation.handleChange}
                            value={item.service || ""}
                            required
                          >
                            <option value={"n/a"}>
                              N/A
                            </option>
                            <option value={"uber"}>
                              Uber
                            </option>
                            <option value={"yango"}>
                              Yango
                            </option>
                            <option value={"careem"}>
                              Careem
                            </option>
                            <option value={"pos"}>
                              POS Machine
                            </option>
                            <option value={"settlement"}>
                              Settlement
                            </option>
                            <option value={"vehicle-services"}>
                              Vehicle services
                            </option>
                            <option value={"passing"}>
                              Passing
                            </option>
                            <option value={"tyre-change"}>
                              Tyre change
                            </option>
                            <option value={"note"}>
                              Note
                            </option>
                            <option value={"other"}>
                              Other
                            </option>
                          </select>
                          {validation.touched.sales?.[index]?.service && validation.errors.sales?.[index]?.service ? (
                            <p className="text-red-400">{validation.errors.sales[index].service}</p>
                          ) : null}

                        </div>
                        {
                          item.service === 'note' &&
                          <div className="xl:col-span-12">
                            <label
                              htmlFor={`sales.${index}.note`}
                              className="inline-block mb-2 text-base font-medium"
                            >
                              Notes
                            </label>
                            <textarea
                              id={`sales.${index}.note`}
                              className="form-input resize-none border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                              placeholder="Leave a message"
                              name={`sales.${index}.note`}
                              onChange={validation.handleChange}
                              value={item.note || ""}
                            />
                          </div>
                        }

                        <div className="col">
                          <button
                            type="button"
                            className="w-full xl:col-span-12 text-center bg-gray-100 hover:bg-gray-200  my-2 p-1 transition-all rounded"
                            onClick={() => removeItem(index)}>
                            <Minus size={20} className="text-gray-700 mx-auto" />
                          </button>
                        </div>
                      </div>
                    </>
                  ))}
                <button
                  type="button"
                  className="w-full xl:col-span-12 text-center bg-custom-500 hover:bg-custom-600 p-1 transition-all rounded"
                  onClick={() => addItem()}
                >
                  <Plus size={20} className=" text-white mx-auto" />
                </button>
              </div>
              {/* <div className="xl:col-span-12">
                <label
                  htmlFor="receivedBy"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Received By
                </label>
                <input
                  type="text"
                  id="receivedBy"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="Name"
                  name="receivedBy"
                  onChange={validation.handleChange}
                  value={validation.values.receivedBy || ""}
                />
                {validation.touched.receivedBy && validation.errors.receivedBy
                  ? (
                    <p className="text-red-400">
                      {validation.errors.receivedBy}
                    </p>
                  )
                  : null}
              </div>
 */}
              {/* <div className="xl:col-span-12">
                <label
                  htmlFor="receivedFrom"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Client Name
                </label>
                <input
                  type="text"
                  id="receivedFrom"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="Name"
                  name="receivedFrom"
                  onChange={validation.handleChange}
                  value={validation.values.receivedFrom || ""}
                />
                {validation.touched.receivedFrom &&
                  validation.errors.receivedFrom
                  ? (
                    <p className="text-red-400">
                      {validation.errors.receivedFrom}
                    </p>
                  )
                  : null}
              </div> */}

              {
                /* <div className="xl:col-span-12">
                <label
                  htmlFor="reason"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Reason
                </label>
                <input
                  type="text"
                  id="reason"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="Reason"
                  name="reason"
                  onChange={validation.handleChange}
                  value={validation.values.reason || ""}
                />
                {validation.touched.reason && validation.errors.reason
                  ? (
                    <p className="text-red-400">
                      {validation.errors.reason}
                    </p>
                  )
                  : null}
              </div> */
              }
              {/* <div className="xl:col-span-12">
                    <label
                      htmlFor="saleDate"
                      className="inline-block mb-2 text-base font-medium"
                    >
                      Date
                    </label>
                    <input
                      type="date"
                      id="saleDate"
                      className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                      placeholder="12345678"
                      name="saleDate"
                      onChange={validation.handleChange}
                      value={validation.values.saleDate || ""}
                    />
                    {validation.touched.saleDate && validation.errors.saleDate
                      ? <p className="text-red-400">{validation.errors.saleDate}</p>
                      : null}
                  </div> */}

              <div className="xl:col-span-12">
                <label
                  htmlFor="vehicle"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Vehicle
                </label>
                <select
                  name="vehicle"
                  id="vehicle"
                  disabled
                  value={validation.values.saleBy === "driver"
                    ? validation.values.driver.assignedVehicle
                    : ""}
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  onChange={validation.handleChange}
                  required
                >
                  {validation.values.saleBy.toLowerCase() === "driver" &&
                    carList.filter((car: any) =>
                      car.assignedDrivers != null
                        ? car.assignedDrivers._id === validation.values.driver
                        : false
                    ).map((car: any) => {
                      return (
                        <option value={car._id} selected>
                          {car.make}, {car.model}
                        </option>
                      );
                    })}
                </select>
              </div>
              {/* <div className="xl:col-span-12">
                <label
                  htmlFor="paymentMethod"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Payment Method
                </label>
                <select
                  name="paymentMethod"
                  id="paymentMethod"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  value={validation.values.paymentMethod || ""}
                  onChange={validation.handleChange}
                  required
                >
                  {paymentMethods.map((method: any, index: any) => (
                    <option value={method.name} key={index}>
                      {method.name}
                    </option>
                  ))}
                </select>
              </div> */}
              {/* <div className="xl:col-span-12">
                <label
                  htmlFor="paymentStatus"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Payment Status
                </label>
                <select
                  name="paymentStatus"
                  id="paymentStatus"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  value={validation.values.paymentStatus || ""}
                  onChange={validation.handleChange}
                  required
                >
                  {paymentStatus.map((status: any, index: any) => (
                    <option value={status.name} key={index}>
                      {status.name}
                    </option>
                  ))}
                </select>
              </div> */}
              {
                /*
                 
              <div className="xl:col-span-12">
                <label
                  htmlFor="invoiceNumber"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Invoice Number (optional)
                </label>
                <input
                  type="text"
                  id="invoiceNumber"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="12345678"
                  name="invoiceNumber"
                  onChange={validation.handleChange}
                  value={validation.values.invoiceNumber || ""}
                />
                {validation.touched.invoiceNumber &&
                  validation.errors.invoiceNumber
                  ? (
                    <p className="text-red-400">
                      {validation.errors.invoiceNumber}
                    </p>
                  )
                  : null}
              </div>
 
                 */
              }
            </div>
            <div className="flex justify-end gap-2 mt-4">
              <button
                type="reset"
                className="text-red-500 bg-white btn hover:text-red-500 hover:bg-red-100 focus:text-red-500 focus:bg-red-100 active:text-red-500 active:bg-red-100 dark:bg-zink-600 dark:hover:bg-red-500/10 dark:focus:bg-red-500/10 dark:active:bg-red-500/10"
                onClick={toggle}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
              >
                {"Create Sale"}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      <Modal
        show={showEdit}
        onHide={toggleEdit}
        modal-center="true"
        className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
        dialogClassName="w-screen md:w-[30rem] bg-white shadow rounded-md dark:bg-zink-600"
      >
        <Modal.Header
          className="flex items-center justify-between p-4 border-b dark:border-zink-500"
          closeButtonClass="transition-all duration-200 ease-linear text-slate-400 hover:text-red-500"
        >
          <Modal.Title className="text-16">{"Update Status"}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <div className="grid grid-cols-1 gap-4 xl:grid-cols-12">

              <div className="xl:col-span-12">
                <label
                  htmlFor="saleBy"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Sale By
                </label>
                <select
                  name="saleBy"
                  id="saleBy"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  value={validation.values.saleBy || ""}
                  onChange={validation.handleChange}
                  required
                >
                  <option value={"office"}>
                    Office
                  </option>
                  <option value={"driver"}>
                    Driver
                  </option>
                </select>
              </div>
              <div className="xl:col-span-12">
                <label
                  htmlFor="driver"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Driver
                </label>
                <select
                  name="driver"
                  id="driver"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  value={validation.values.driver || ""}
                  onChange={validation.handleChange}
                  required
                >
                  <option value={""} >N/A</option>
                  {userList.map((driver) =>
                    // @ts-ignore
                    <option value={driver._id}>{driver.user.name}</option>
                  )}
                </select>
              </div>
              <div className="xl:col-span-12">
                <label
                  htmlFor="saleAmount"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Amount
                </label>
                <input
                  type="text"
                  id="saleAmount"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="12345678"
                  name="saleAmount"
                  onChange={validation.handleChange}
                  value={validation.values.saleAmount || ""}
                />
                {validation.touched.saleAmount && validation.errors.saleAmount
                  ? (
                    <p className="text-red-400">
                      {validation.errors.saleAmount}
                    </p>
                  )
                  : null}
              </div>

              {/* <div className="xl:col-span-12">
                <label
                  htmlFor="paymentStatus"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Status
                </label>
                <select
                  name="paymentStatus"
                  id="paymentStatus"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  value={validation.values.paymentStatus || ""}
                  onChange={validation.handleChange}
                  required
                >
                  {paymentStatus.map((status: any, index: any) => (
                    <option value={status.name} key={index}>
                      {status.name}
                    </option>
                  ))}
                </select>
              </div> */}
            </div>

            <div className="flex justify-end gap-2 mt-4">
              <button
                type="reset"
                className="text-red-500 bg-white btn hover:text-red-500 hover:bg-red-100 focus:text-red-500 focus:bg-red-100 active:text-red-500 active:bg-red-100 dark:bg-zink-600 dark:hover:bg-red-500/10 dark:focus:bg-red-500/10 dark:active:bg-red-500/10"
                onClick={toggleEdit}
              >
                Cancel
              </button>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  handleUpdateStatus(
                    editId,
                    validation.values.paymentStatus,
                  );
                }}
                className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
              >
                {"Update Sale"}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default Sales;
