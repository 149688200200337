import React, { useEffect } from 'react';
import BreadCrumb from 'Common/BreadCrumb';
import Widgets from './Widgets';
import axios from 'axios';
import toast from 'react-hot-toast';
import { Loader } from 'lucide-react';

const Analytics = () => {

  const [loading, setLoading] = React.useState<boolean>(false);
  const [filtered, setFiltered] = React.useState<boolean>(false);
  const [data, setData] = React.useState({
    totalUsers: 0,
    totalCars: 0,
    totalDrivers: 0,
    totalExpenses: 0,
    totalSales: 0,
    pendingExpenses: 0,
    pendingSales: 0,
    totalContacts: 0,
    pendingContacts: 0,
    totalEmployees: 0,
  });
  const [filterDate, setFilterDate] = React.useState({
    startDate: "",
    endDate: "",
    handleChange: (e: any) => {
      setFilterDate((prevDate) => ({
        ...prevDate,
        [e.target.name]: e.target.value,
      }));
    },
  });

  const handleFilterDate = async () => {
    try {
      setLoading(true);
      const res = await axios.get(`${process.env.REACT_APP_BASE_URI}/crm/analytics/filter`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        params: {
          startDate: filterDate.startDate,
          endDate: filterDate.endDate
        }
      })
      const {
        totalUsers,
        totalCars,
        totalDrivers,
        totalExpenses,
        totalSales,
        pendingExpenses,
        pendingSales,
        totalContacts,
        pendingContacts,
        totalEmployees,
      } = res.data;
      setFiltered(true);
      setData({
        totalUsers,
        totalCars,
        totalDrivers,
        totalExpenses,
        totalSales,
        pendingExpenses,
        pendingSales,
        totalContacts,
        pendingContacts,
        totalEmployees
      })
    } catch (error: any) {
      toast.error(error.response.data.error);
    }
    finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    handleGetStats();
  }, []);

  const handleGetStats = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URI}/crm/analytics`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        },
      );
      const {
        totalUsers,
        totalCars,
        totalDrivers,
        totalExpenses,
        totalSales,
        pendingExpenses,
        pendingSales,
        totalContacts,
        pendingContacts,
        totalEmployees,
      } = res.data;
      setData({
        totalUsers,
        totalCars,
        totalDrivers,
        totalExpenses,
        totalSales,
        pendingExpenses,
        pendingSales,
        totalContacts,
        pendingContacts,
        totalEmployees,
      });
    } catch (error: any) {
      if (!error.response) {
        return toast.error("Network error. Please try again.");
      }
      if (typeof error.response.data === "string") {
        return toast.error(error.response.data);
      }
      toast.error("An error occurred. Please try again.");
    }
    setLoading(false);
  };


  return (
    <React.Fragment>
      {loading && (
        <div className="fixed inset-0 z-50 flex items-center justify-center w-full h-full bg-white bg-opacity-90 dark:bg-zink-900 dark:bg-opacity-90">
          <Loader className="size-10 text-custom-500 animate-spin" />
        </div>
      )}
      <BreadCrumb title='Analytics' pageTitle='Dashboards' />
      <div className="text-slate-400 flex flex-row justify-end items-end gap-4 w-full py-3">
        <div className="lg:w-1/5 md:w-1/3 w-full flex flex-row gap-2 items-center">
          <label
            htmlFor="startDate"
            className="inline-block mb-2 text-base font-medium"
          >
            From
          </label>
          <input
            type="date"
            id="startDate"
            className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
            name="startDate"
            onChange={filterDate.handleChange}
            value={filterDate.startDate || ""}
          />
        </div>

        <div className="lg:w-1/5 md:w-1/3 w-ful flex flex-row gap-2 items-center">
          <label
            htmlFor="endDate"
            className="inline-block mb-2 text-base font-medium"
          >
            To
          </label>
          <input
            type="date"
            id="endDate"
            className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
            name="endDate"
            onChange={filterDate.handleChange}
            value={filterDate.endDate || ""}
          />
        </div>

        <button
          onClick={(e) => {
            e.preventDefault();
            handleFilterDate();
          }}
          className="text-custom-500 btn bg-transparent border-custom-500 hover:text-white hover:bg-custom-500 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
        >
          {"Filter"}
        </button>

        <button
          onClick={(e) => {
            e.preventDefault();
            setFiltered(false);
            handleGetStats();
          }}
          disabled={!filtered}
          className={`text-custom-500 btn bg-transparent border-custom-500 hover:text-white hover:bg-custom-500 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20 disabled:hover:bg-transparent disabled:hover:text-custom-500 ${!filtered ? "opacity-70 hover:" : ""}`}
        >
          {"Reset Filter"}
        </button>
      </div>
      <div className="grid grid-cols-12 gap-x-5">
        <Widgets data={data} />
      </div>
    </React.Fragment>
  );
};

export default Analytics;