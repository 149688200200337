import React, { useState, useEffect, useRef, useMemo } from "react";
import BreadCrumb from "Common/BreadCrumb";
import { toast } from "react-hot-toast";
import {
  Download,
  Printer,
  Search,
  X,
  ChevronDown,
  ChevronUp,
  Calendar,
  Loader,
} from "lucide-react";
import { ToastContainer } from "react-toastify";
import axios from "axios";
import moment from "moment";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { enUS } from "date-fns/locale";

interface PettyCashEntry {
  date: string;
  debit: number;
  credit: number;
  note: string;
  balance?: number; // Add balance to the interface
  isPersonal?: boolean; 
}

const PettyCashReport: React.FC = () => {
  const currentYear = new Date().getFullYear();
  const [pettyCashData, setPettyCashData] = useState<PettyCashEntry[]>([]);
  const [filteredData, setFilteredData] = useState<PettyCashEntry[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState({
    startDate: new Date(currentYear, 0, 1),
    endDate: new Date(currentYear, 11, 31),
    key: "selection",
  });
  const [showDateRange, setShowDateRange] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [isCustomDateRange, setIsCustomDateRange] = useState<boolean>(false);
  const [sortConfig, setSortConfig] = useState<{
    key: keyof PettyCashEntry | null;
    direction: "ascending" | "descending";
  }>({ key: null, direction: "ascending" });
  const dateRangeRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    fetchPettyCashData();
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dateRangeRef.current &&
      !dateRangeRef.current.contains(event.target as Node)
    ) {
      setShowDateRange(false);
    }
  };

  const fetchPettyCashData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URI}/petty-cash/all`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      const formattedData = response.data.data.map((entry: any) => ({
        date: entry.date,
        debit:
          entry.transactionType.toLowerCase() === "debit" ? entry.amount : 0,
        credit:
          entry.transactionType.toLowerCase() === "credit" ? entry.amount : 0,
        note: entry.note,
        isPersonal: entry.isPersonal || false, // Add this line
      }));
      setPettyCashData(formattedData);
      setFilteredData(formattedData);
    } catch (error) {
      console.error("Error fetching petty cash data:", error);
      toast.error("Failed to fetch petty cash data");
    } finally {
      setLoading(false);
    }
  };

  const filterData = (startDate: Date, endDate: Date, term: string) => {
    let filtered = pettyCashData;

    if (isCustomDateRange) {
      const adjustedEndDate = new Date(endDate);
      adjustedEndDate.setDate(adjustedEndDate.getDate() + 1);
      adjustedEndDate.setMilliseconds(adjustedEndDate.getMilliseconds() - 1);

      filtered = filtered.filter((entry) => {
        const entryDate = new Date(entry.date);
        return entryDate >= startDate && entryDate <= adjustedEndDate;
      });
    }

    if (term) {
      filtered = filtered.filter(
        (entry) =>
          entry.note.toLowerCase().includes(term.toLowerCase()) ||
          entry.debit.toString().includes(term) ||
          entry.credit.toString().includes(term)
      );
    }

    setFilteredData(filtered);
  };

  const handleDateRangeChange = (item: any) => {
    setDateRange(item.selection);
    setIsCustomDateRange(true);
    const { startDate, endDate } = item.selection;
    filterData(startDate, endDate, searchTerm);
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const term = event.target.value;
    setSearchTerm(term);
    filterData(dateRange.startDate, dateRange.endDate, term);
  };

  const clearFilters = () => {
    setDateRange({
      startDate: new Date(currentYear, 0, 1),
      endDate: new Date(currentYear, 11, 31),
      key: "selection",
    });
    setIsCustomDateRange(false);
    setSearchTerm("");
    setFilteredData(pettyCashData);
    setSortConfig({ key: null, direction: "ascending" });
  };

  const formatNumber2 = (num: number) => {
    return num.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };

  const calculateOpeningBalance = (): number => {
    return filteredData.reduce((acc, entry) => 
      acc + (entry.isPersonal ? 0 : entry.credit), 0);
  };

  const downloadPDF = () => {
    const doc = new jsPDF();
    const entriesFirstPage = 25;
    const entriesPerPage = 32;
    let pageNumber = 1;
  
    const openingBalance = calculateOpeningBalance();
  
    doc.setFontSize(20);
    doc.text("SZL Luxury Motors Vehicles", 105, 15, { align: 'center' });
  
    doc.setFontSize(18);
    doc.text("Petty Cash Report", 105, 25, { align: 'center' });
  
    doc.setFontSize(12);
    doc.text(`Date Range: ${moment(dateRange.startDate).format('DD/MM/YYYY')} - ${moment(dateRange.endDate).format('DD/MM/YYYY')}`, 14, 35);
    doc.text(`Total Entries: ${sortedData.length}`, 14, 42);
    doc.text(`Opening Balance: ${formatNumber2(openingBalance)}`, 14, 49);
  
    const tableColumn = ["Date", "Debit", "Credit", "Note", "Closing Balance"];
  
    const formatNumber = (num: number) => {
      return num.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    };
  
    for (let i = 0; i < sortedData.length; i += (i === 0 ? entriesFirstPage : entriesPerPage)) {
      if (i !== 0) {
        doc.addPage();
        pageNumber++;
      }
  
      const pageEntries = sortedData.slice(i, i + (i === 0 ? entriesFirstPage : entriesPerPage));
      const tableRows = pageEntries.map((entry) => [
        moment(entry.date).format('DD/MM/YYYY'),
        formatNumber(entry.debit),
        formatNumber(entry.credit),
        entry.isPersonal ? 
          { content: `PERSONAL PETTY CASH - ${entry.note}`, styles: { textColor: [0, 0, 255], fontStyle: 'bold' } } : 
          entry.note,
        formatNumber(entry.balance || 0)
      ]);
  
      const pageSubtotalDebit = pageEntries.reduce((sum, entry) => sum + entry.debit, 0);
      const pageSubtotalCredit = pageEntries.reduce((sum, entry) => sum + (entry.isPersonal ? 0 : entry.credit), 0);
      const pageSubtotalBalance = pageEntries[pageEntries.length - 1]?.balance || 0;
  
      tableRows.push([
        'Total',
        formatNumber(pageSubtotalDebit),
        formatNumber(pageSubtotalCredit),
        '',
        formatNumber(pageSubtotalBalance)
      ]);
  
      //@ts-ignore
      doc.autoTable({
        head: [tableColumn],
        body: tableRows,
        startY: i === 0 ? 56 : 15,
        theme: 'grid',
        styles: { fontSize: 8 },
        headStyles: { fillColor: [200, 200, 200], textColor: [0, 0, 0] },
        alternateRowStyles: { fillColor: [245, 245, 245] },
        footStyles: { fillColor: [220, 220, 220], textColor: [0, 0, 0], fontStyle: 'bold' },
        foot: [tableRows[tableRows.length - 1]]
      });
  
      doc.setFontSize(10);
      doc.text(`Page ${pageNumber}`, doc.internal.pageSize.width - 20, doc.internal.pageSize.height - 10);
    }
  
    const grandTotalDebit = calculateTotalDebit();
    const grandTotalCredit = calculateTotalCredit();
    const grandTotalBalance = sortedData[sortedData.length - 1]?.balance || 0;
  
    const startY = (doc as any).lastAutoTable.finalY + 20;
  
    const grandTotalRows = [
      [
        { content: 'Grand Total', styles: { fontStyle: 'bold' } }, 
        `Debit: ${formatNumber(grandTotalDebit)}`, 
        `Credit: ${formatNumber(grandTotalCredit)}`, 
        `Balance: ${formatNumber(grandTotalBalance)}`
      ]
    ];
  
    //@ts-ignore
    doc.autoTable({
      startY: startY,
      body: grandTotalRows,
      theme: 'plain',
      styles: { fontSize: 10, halign: 'center' },
      tableWidth: 'auto',
      margin: { left: 14 },
      didDrawCell: (data:any) => {
        if (data.section === 'body') {
          doc.rect(data.cell.x, data.cell.y, data.cell.width, data.cell.height, 'S');
        }
      }
    });
  
    doc.save("petty-cash-report.pdf");
  };


  const calculateBalance = (): number => {
    return filteredData.reduce((acc, entry) => {
      return acc + (entry.isPersonal ? 0 : entry.credit) - entry.debit;
    }, 0);
  };

  const calculateRunningBalance = (
    data: PettyCashEntry[]
  ): PettyCashEntry[] => {
    let balance = 0;
    return data.map((entry) => {
      balance += (entry.isPersonal ? 0 : entry.credit) - entry.debit;
      return { ...entry, balance };
    });
  };
  
  const sortedData = useMemo(() => {
    let sortableItems = [...filteredData];
    if (sortConfig.key !== null) {
      sortableItems.sort((a, b) => {
        //@ts-ignore
        if (a[sortConfig.key!] < b[sortConfig.key!]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        //@ts-ignore
        if (a[sortConfig.key!] > b[sortConfig.key!]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return calculateRunningBalance(sortableItems);
  }, [filteredData, sortConfig]);

  const calculateTotalCredit = (): number => {
    return sortedData.reduce((acc, entry) => 
      acc + (entry.isPersonal ? 0 : entry.credit), 0);
  };

  const calculateTotalDebit = (): number => {
    return sortedData.reduce((acc, entry) => acc + entry.debit, 0);
  };

  const requestSort = (key: keyof PettyCashEntry) => {
    let direction: "ascending" | "descending" = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  return (
    <React.Fragment>
      {loading && (
        <div className="fixed inset-0 z-50 flex items-center justify-center w-full h-full bg-white bg-opacity-90 dark:bg-zink-900 dark:bg-opacity-90">
          <Loader className="size-10 text-custom-500 animate-spin" />
        </div>
      )}
      <BreadCrumb title="Petty Cash Report" pageTitle="Reports" />
      <ToastContainer closeButton={false} limit={1} />
      <div className="card shadow-lg">
        <div className="card-body">
          <div className="flex items-center justify-between mb-6">
            <h5 className="text-2xl font-semibold text-gray-700">
              Petty Cash Management
            </h5>
            <div className="flex gap-3">
              <button
                onClick={() => setShowDateRange(!showDateRange)}
                className="btn btn-primary flex items-center gap-2"
              >
                <Calendar size={16} />
                Date Range
              </button>
              <button
                onClick={downloadPDF}
                className="btn btn-secondary flex items-center gap-2"
              >
                <Download size={16} /> Export PDF
              </button>
              
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-5 gap-4 mb-6">
            <div className="bg-blue-100 p-4 rounded-lg shadow">
              <h6 className="text-lg font-semibold mb-2">Current Balance</h6>
              <p className="text-2xl font-bold text-blue-600">
                {calculateBalance().toFixed(2)}
              </p>
            </div>
            <div className="bg-green-100 p-4 rounded-lg shadow">
              <h6 className="text-lg font-semibold mb-2">Total Credit</h6>
              <p className="text-2xl font-bold text-green-600">
                {calculateTotalCredit().toFixed(2)}
              </p>
            </div>
            <div className="bg-red-100 p-4 rounded-lg shadow">
              <h6 className="text-lg font-semibold mb-2">Total Debit</h6>
              <p className="text-2xl font-bold text-red-600">
                {calculateTotalDebit().toFixed(2)}
              </p>
            </div>
            <div className="bg-green-100 p-4 rounded-lg shadow">
              <h6 className="text-lg font-semibold mb-2">Date Range</h6>
              <p className="text-sm">
                {moment(dateRange.startDate).format("DD/MM/YYYY")} -{" "}
                {moment(dateRange.endDate).format("DD/MM/YYYY")}
              </p>
            </div>
            <div className="bg-yellow-100 p-4 rounded-lg shadow">
              <h6 className="text-lg font-semibold mb-2">Total Entries</h6>
              <p className="text-2xl font-bold text-yellow-600">
                {filteredData.length}
              </p>
            </div>
          </div>

          <div className="relative mb-6">
            <input
              type="text"
              placeholder="Search by note, debit, or credit amount..."
              value={searchTerm}
              onChange={handleSearch}
              className="form-input pl-10 pr-4 py-2 w-full"
            />
            <Search
              className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
              size={20}
            />
          </div>

          {showDateRange && (
            <div
              ref={dateRangeRef}
              className="absolute z-10 bg-white shadow-lg rounded-lg p-4"
            >
              <DateRangePicker
                ranges={[dateRange]}
                onChange={handleDateRangeChange}
                className="mb-4"
                locale={enUS}
              />
            </div>
          )}

          <div className="overflow-x-auto">
            <h6 className="text-lg font-semibold mb-2">
              Detailed Transactions
            </h6>
            <table className="w-full table-auto border-collapse">
              <thead>
                <tr className="bg-gray-100">
                  <th
                    className="px-4 py-2 text-left cursor-pointer"
                    onClick={() => requestSort("date")}
                  >
                    Date{" "}
                    {sortConfig.key === "date" &&
                      (sortConfig.direction === "ascending" ? (
                        <ChevronUp size={14} />
                      ) : (
                        <ChevronDown size={14} />
                      ))}
                  </th>
                  <th
                    className="px-4 py-2 text-left cursor-pointer"
                    onClick={() => requestSort("debit")}
                  >
                    Debit{" "}
                    {sortConfig.key === "debit" &&
                      (sortConfig.direction === "ascending" ? (
                        <ChevronUp size={14} />
                      ) : (
                        <ChevronDown size={14} />
                      ))}
                  </th>
                  <th
                    className="px-4 py-2 text-left cursor-pointer"
                    onClick={() => requestSort("credit")}
                  >
                    Credit{" "}
                    {sortConfig.key === "credit" &&
                      (sortConfig.direction === "ascending" ? (
                        <ChevronUp size={14} />
                      ) : (
                        <ChevronDown size={14} />
                      ))}
                  </th>
                  <th
                    className="px-4 py-2 text-left cursor-pointer"
                    onClick={() => requestSort("note")}
                  >
                    Note{" "}
                    {sortConfig.key === "note" &&
                      (sortConfig.direction === "ascending" ? (
                        <ChevronUp size={14} />
                      ) : (
                        <ChevronDown size={14} />
                      ))}
                  </th>
                  <th className="px-4 py-2 text-left">Closing Balance</th>
                </tr>
              </thead>
              <tbody>
                {sortedData.map((entry, index) => (
                  <tr
                    key={index}
                    className={index % 2 === 0 ? "bg-white" : "bg-gray-50"}
                  >
                    <td className="border-t px-4 py-2">
                      {moment(entry.date).format("DD/MM/YYYY")}
                    </td>
                    <td className="border-t px-4 py-2 text-red-600">
                      {entry.debit > 0 ? entry.debit.toFixed(2) : "-"}
                    </td>
                    <td className="border-t px-4 py-2 text-green-600">
                      {entry.credit > 0 ? entry.credit.toFixed(2) : "-"}
                    </td>
                    <td className="border-t px-4 py-2">
                      {entry.isPersonal ?  <span
                      className=" font-bold text-blue-700"
                      >Personal Petty Cash -  <span
                      className="text-black font-medium"
                      >{entry.note}</span> </span> : entry.note}
                     </td>
                    <td className="border-t px-4 py-2">
                      {entry.balance?.toFixed(2)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {(searchTerm || isCustomDateRange) && (
            <div className="mt-4 flex justify-end">
              <button
                onClick={clearFilters}
                className="btn btn-outline-danger flex items-center gap-2"
              >
                <X size={16} /> Clear Filters
              </button>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default PettyCashReport;
