import {
  BadgeDollarSign,
  Building,
  Car,
  FileText,
  MonitorDot,
  UserRound,
} from "lucide-react";

const menuData: any = [
  {
    label: "menu",
    isTitle: true,
  },
  {
    id: "dashboard",
    label: "Dashboard",
    link: "/",
    icon: <MonitorDot />,
  },
  // {
  //   label: "Drivers",
  //   isTitle: true,
  // },
  // {
  //   id: "drivers",
  //   label: "Manage Drivers",
  //   link: "/drivers",
  //   icon: <Car />,
  // },
  // {
  //   label: "Cars",
  //   isTitle: true,
  // },
  // {
  //   id: "cars",
  //   label: "Manage Cars",
  //   link: "/cars",
  //   icon: <Car />,
  // },
  {
    label: "Financial",
    isTitle: true,
  },
  {
    id: "sales",
    label: "Sales",
    icon: <BadgeDollarSign />,
    link: "/sales",
    parentId: 2,
  },
  {
    id: "purchases",
    label: "Purchases",
    icon: <BadgeDollarSign />,
    link: "/purchases",
    parentId: 2,
  },
  {
    label: "Human Resources",
    isTitle: true,
  },
  {
    id: "departments",
    label: "Departments",
    icon: <BadgeDollarSign />,
    link: "/departments",
    parentId: 2,
  },
  {
    id: "employees",
    label: "Employees",
    icon: <BadgeDollarSign />,
    link: "/employees",
    parentId: 2,
  },
 
 
 
];

export { menuData };
