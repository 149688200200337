import React, { useEffect, useState } from "react";
import BreadCrumb from "Common/BreadCrumb";
// icons
import { Download, Loader } from "lucide-react";

import { ToastContainer } from "react-toastify";
// @ts-ignore
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import VoucherPdf from "components/VoucherPdf/VoucherPdf";
import { VoucherData } from "types";
import { PDFDownloadLink } from "@react-pdf/renderer";
import axios from "axios";
import { set } from "date-fns";


const Vouchers = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [isGenerating, setIsGenerating] = useState<boolean>(false);
    const [voucherData, setVoucherData] = useState<VoucherData>({
        date: new Date().toString(),
        paidTo: "",
        sumOfDhs: "",
        voucherCount: 0,
        settlementOf: "",
        by: "",
        voucherType: "Receipt"
    });
    const [voucherCounts, setVoucherCounts] = useState<{ payment: number, receipt: number }>({ payment: 0, receipt: 0 });

    const handleIncrementCounter = async () => {
        try {
            await axios.put(`${process.env.REACT_APP_BASE_URI}/erp/vouchers/${voucherData.voucherType.toLowerCase()}`, {}, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            });
            console.log("Voucher incremented");
        } catch (error) {
            console.log(error);
        }
    }

    const handleDownloadClick = async () => {
        setIsGenerating(true);
        await handleIncrementCounter(); // ensure this is a Promise if it's async
        setVoucherData({
            ...voucherData,
            voucherCount: voucherData.voucherType === "Payment"
                ? voucherCounts.payment + 1
                : voucherCounts.receipt + 1
        });
        setIsGenerating(false);
    };

    const fetchVoucherCounts = async () => {
        setLoading(true);
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_BASE_URI}/erp/vouchers`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            });
            setVoucherCounts({
                payment: data.paymentCounter + 1,
                receipt: data.receiptCounter + 1
            });
            setVoucherData({ ...voucherData, voucherCount: voucherData.voucherType === "Payment" ? data.paymentCounter + 1 : data.receiptCounter + 1 });
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchVoucherCounts();
    }, [])

    useEffect(() => {
        setVoucherData({ ...voucherData, voucherCount: voucherData.voucherType === "Payment" ? voucherCounts.payment : voucherCounts.receipt });
    }, [voucherData.voucherType])


    return (
        <React.Fragment>
            <BreadCrumb title="Vouchers Reports" pageTitle="Management" />
            <ToastContainer closeButton={false} limit={1} />

            {loading && (
                <div className="fixed inset-0 z-50 flex items-center justify-center w-full h-full bg-white bg-opacity-90 dark:bg-zink-900 dark:bg-opacity-90">
                    <Loader className="size-10 text-custom-500 animate-spin" />
                </div>
            )}
            <div className="grid grid-cols-1 gap-x-5 xl:grid-cols-12">
                <div className="xl:col-span-12">
                    <div className="card " id="Drivers">
                        <div className="card-body">
                            <div className=" w-full flex xl:flex-row flex-col gap-5 mt-2">
                                <div className="xl:w-2/3 lg:w-full w-full " id="report-pdf">
                                    <VoucherPdf voucherData={voucherData} />
                                </div>
                                <div className="xl:w-1/3 lg:w-full w-full">
                                    <div className="grid grid-cols-1 gap-2">
                                        <div>
                                            <h5>Select Voucher Type</h5>
                                            <select
                                                onChange={(e) => {
                                                    setVoucherData({ ...voucherData, voucherType: e.target.value as "Payment" | "Receipt" });
                                                }}
                                                className="border w-full dark:bg-transparent mt-2 p-2 rounded-md text-md px-2"
                                            >
                                                <option value="Payment" selected>Payment</option>
                                                <option value="Receipt" selected>Receipt</option>
                                            </select>
                                        </div>
                                        <input
                                            type="date"
                                            id="date"
                                            className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                            placeholder="12345678"
                                            name="date"
                                            onChange={(e) => setVoucherData({ ...voucherData, date: e.target.value })}
                                            value={voucherData.date}
                                        />
                                        <input
                                            type="text"
                                            id="paidTo"
                                            className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                            placeholder="Driver's name"
                                            name="paidTo"
                                            onChange={(e) => setVoucherData({ ...voucherData, paidTo: e.target.value })}
                                            value={voucherData.paidTo}
                                        />
                                        <input
                                            type="text"
                                            id="sumOfDhs"
                                            className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                            placeholder="Sum of DHS"
                                            name="sumOfDhs"
                                            onChange={(e) => setVoucherData({ ...voucherData, sumOfDhs: e.target.value })}
                                            value={voucherData.sumOfDhs}
                                        />
                                        <input
                                            type="text"
                                            id="by"
                                            className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                            placeholder="By"
                                            name="by"
                                            onChange={(e) => setVoucherData({ ...voucherData, by: e.target.value })}
                                            value={voucherData.by}
                                        />
                                        <textarea
                                            id="settlementOf"
                                            rows={3}
                                            className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                            placeholder="In settlement of"
                                            name="settlementOf"
                                            onChange={(e) => setVoucherData({ ...voucherData, settlementOf: e.target.value })}
                                            value={voucherData.settlementOf}
                                        />
                                    </div>
                                    <PDFDownloadLink
                                        className="bg-white mt-4 border-dashed w-full text-custom-500 btn border-custom-500 hover:text-custom-500 hover:bg-custom-50 hover:border-custom-600 focus:text-custom-600 focus:bg-custom-50 focus:border-custom-600 active:text-custom-600 active:bg-custom-50 active:border-custom-600 dark:bg-zink-700 dark:ring-custom-400/20 dark:hover:bg-custom-800/20 dark:focus:bg-custom-800/20 dark:active:bg-custom-800/20"
                                        onClick={() => {
                                            handleIncrementCounter();
                                            setVoucherData({
                                                ...voucherData,
                                                voucherCount: voucherData.voucherType === "Payment" ? voucherCounts.payment + 1 : voucherCounts.receipt + 1
                                            });
                                            setVoucherCounts({
                                                payment: voucherData.voucherType === "Payment" ? voucherCounts.payment + 1 : voucherCounts.payment,
                                                receipt: voucherData.voucherType === "Receipt" ? voucherCounts.receipt + 1 : voucherCounts.receipt
                                            });
                                        }}
                                        document={<VoucherPdf voucherData={voucherData} />}
                                        fileName="voucher.pdf"
                                    >
                                        {({ blob, url, loading, error }) => (
                                            loading ?
                                                'Loading document...'
                                                :
                                                error ?
                                                    "Error generating document"
                                                    :
                                                    <>
                                                        <Download className="inline-block size-4" />{" "}
                                                        <span className="align-middle">Export</span>
                                                    </>
                                        )}
                                    </PDFDownloadLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Vouchers;
