// @ts-nocheck
import {
  Document,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import { capitalizeFirstLetter, toReadableDate } from "helpers/utils";
import letterHead from "assets/images/letterHead.jpg";

// Create styles
const styles = StyleSheet.create({
  page: {
    width: "100%",
    // height: "3508px",
    borderRadius: 5,
  },
  section: {
    padding: 10,
    color: "black",
    width: "100%",
  },
});

const daysPassed = (date: Date) => {
  const today = new Date();
  const diffTime = Math.abs(today.getTime() - date.getTime());
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
};

// sum up amount field in all expenses
const sumAmount = (expenses: any) => {
  return expenses?.reduce((acc: any, item: any) => acc + item.amount, 0);
};

// check if a service in expenses is empty
const checkEmpty = (expenses: any, service: string) => {
  return expenses?.filter((item: any) => item.service === service).length === 0;
};

// Create Document Component
const ExpenseReportPdf = ({ report }: { report: any }) => (
  <>
    <Document style={{ width: "100%" }} className="w-full">
      <Page
        size="A4"
        style={styles.page}
        className="flex flex-col w-full relative"
      >
        <img
          src={letterHead}
          className="w-full relative top-0"
        />
        {
          /* <View style={styles.section} className="flex flex-col gap-1">
          <View className="flex flex-row gap-4">
            <Text className="font-semibold">Make</Text>
            <Text>{report?.make}</Text>
          </View>
          <View className="flex flex-row gap-4">
            <Text className="font-semibold">Model</Text>
            <Text>{report?.model}</Text>
          </View>
          <View className="flex flex-row gap-4">
            <Text className="font-semibold">Creation Date</Text>
            <Text>{report ? toReadableDate(report?.createdAt) : ""}</Text>
          </View>
          <View className="flex flex-row gap-4">
            <Text className="font-semibold">Registration Valid Till</Text>
            <Text>{toReadableDate(report?.validRegistration)}</Text>
          </View>
          <View className="flex flex-row gap-4">
            <Text className="font-semibold">Vehicle Registration Number</Text>
            <Text>{report?.registrationNumber}</Text>
          </View>
          <View className="flex flex-col gap-1">
            <Text className="font-semibold">Assigned Drivers</Text>
            <Text>
              {report?.assignedDrivers?.map((
                driver: any,
                index: any,
              ) => (
                <View className="flex flex-row justify-between gap-5">
                  <Text>
                    {++index}. {driver?.user?.name}
                  </Text>
                </View>
              ))}
            </Text>
          </View>
        </View> */
        }

        <View className="flex flex-row z-10 absolute top-44 text-sm w-full">
          <View
            className="flex p-5 flex-col w-full"
          >
            <View className="flex flex-col w-full gap-1 p-1">
              <View className="flex font-semibold flex-row justify-between">
                <Text className="w-1/5">Date</Text>
                <Text className="w-1/5">Purchase By</Text>
                <Text className="w-1/5">Driver</Text>
                <Text className="w-1/5">Status</Text>
                <Text className="w-1/5 text-right">Amount AED</Text>
              </View>
              <View className="flex flex-col justify-between">
                {report?.map((item) => (
                  <View className="flex flex-row justify-between w-full">
                    <Text className="w-1/5">
                      {toReadableDate(item.createdAt)}
                    </Text>
                    <Text className="w-1/5">
                      {capitalizeFirstLetter(item.expenseBy)}
                    </Text>
                    <Text className="w-1/5">
                      {item.driver ? item.driver?.user.name : "N/A"}
                    </Text>
                    <Text className="w-1/5">
                      {capitalizeFirstLetter(item.approvalStatus)}
                    </Text>
                    <Text className="w-1/5 text-right">
                      {item.amount}
                    </Text>
                  </View>
                ))}
              </View>
              <View className="flex mt-2 bg-primary-green/30 rounded p-1 items-center font-semibold flex-row justify-between">
                <Text className="text-primary-green">Total</Text>
                <Text className="text-primary-green">
                  {report ? sumAmount(report) : 0}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  </>
);

export default ExpenseReportPdf;
