import React from "react";
import {
  ArrowUp,
  BadgeDollarSignIcon,
  CarFront,
  Kanban,
  Users,
} from "lucide-react";
import CountUp from "react-countup";

interface Stats {
  totalUsers: number;
  totalCars: number;
  totalDrivers: number;
  totalExpenses: number;
  totalSales: number;
  pendingExpenses: number;
  pendingSales: number;
  totalContacts: number;
  pendingContacts: number;
  totalEmployees: number;
}

const Widgets = ({ data }: { data: Stats }) => {

  return (
    <React.Fragment>
      {/* <div className="order-2 md:col-span-6 lg:col-span-3 col-span-12 2xl:order-1 bg-green-100 dark:bg-green-500/20 card 2xl:col-span-2 group-data-[skin=bordered]:border-purple-500/20 relative overflow-hidden">
        <div className="card-body">
          <Kanban className="absolute top-0 size-32 stroke-1 text-green-200/50 dark:text-green-500/20 ltr:-right-10 rtl:-left-10">
          </Kanban>
          <div className="flex items-center justify-center size-12 bg-green-500 rounded-md text-15 text-green-50">
            <CarFront />
          </div>
          <h5 className="mt-5 mb-2">
            <CountUp end={data.totalCars} className="counter-value" />
          </h5>
          <p className="text-slate-500 dark:text-slate-200">Assets</p>
        </div>
      </div> */}
      <div className="order-3 md:col-span-6 lg:col-span-3 col-span-12 2xl:order-1 bg-purple-100 dark:bg-purple-500/20 card 2xl:col-span-2 group-data-[skin=bordered]:border-purple-500/20 relative overflow-hidden">
        <div className="card-body">
          <Kanban className="absolute top-0 size-32 stroke-1 text-purple-200/50 dark:text-purple-500/20 ltr:-right-10 rtl:-left-10">
          </Kanban>
          <div className="flex items-center justify-center size-12 bg-purple-500 rounded-md text-15 text-purple-50">
            <BadgeDollarSignIcon />
          </div>
          <h5 className="mt-5 mb-2">
            <CountUp end={data.totalSales} className="counter-value" />
          </h5>
          <p className="text-slate-500 dark:text-slate-200">
            Sales (AED)
          </p>
        </div>
      </div>
      <div className="order-2 md:col-span-6 lg:col-span-3 col-span-12 2xl:order-1 bg-orange-100 dark:bg-orange-500/20 card 2xl:col-span-2 group-data-[skin=bordered]:border-purple-500/20 relative overflow-hidden">
        <div className="card-body">
          <Kanban className="absolute top-0 size-32 stroke-1 text-orange-200/50 dark:text-orange-500/20 ltr:-right-10 rtl:-left-10">
          </Kanban>
          <div className="flex items-center justify-center size-12 bg-orange-500 rounded-md text-15 text-orange-50">
            <BadgeDollarSignIcon />
          </div>
          <h5 className="mt-5 mb-2">
            <CountUp end={data.totalExpenses} className="counter-value" />
          </h5>
          <p className="text-slate-500 dark:text-slate-200">
            Purchases (AED)
          </p>
        </div>
      </div>
      {/* Pending Expenses */}
      <div className="order-2 md:col-span-6 lg:col-span-3 col-span-12 2xl:order-1 bg-green-100 dark:bg-green-500/20 card 2xl:col-span-2 group-data-[skin=bordered]:border-purple-500/20 relative overflow-hidden">
        <div className="card-body">
          <Kanban className="absolute top-0 size-32 stroke-1 text-green-200/50 dark:text-green-500/20 ltr:-right-10 rtl:-left-10">
          </Kanban>
          <div className="flex items-center justify-center size-12 bg-green-500 rounded-md text-15 text-green-50">
            <BadgeDollarSignIcon />
          </div>
          <h5 className="mt-5 mb-2">
            <CountUp end={data.pendingExpenses} className="counter-value" />
          </h5>
          <p className="text-slate-500 dark:text-slate-200">
            Payables (AED)
          </p>
        </div>
      </div>

      {/* Pending Sales */}
      <div className="order-2 md:col-span-6 lg:col-span-3 col-span-12 2xl:order-1 bg-orange-100 dark:bg-orange-500/20 card 2xl:col-span-2 group-data-[skin=bordered]:border-purple-500/20 relative overflow-hidden">
        <div className="card-body">
          <Kanban className="absolute top-0 size-32 stroke-1 text-orange-200/50 dark:text-orange-500/20 ltr:-right-10 rtl:-left-10">
          </Kanban>
          <div className="flex items-center justify-center size-12 bg-orange-500 rounded-md text-15 text-orange-50">
            <BadgeDollarSignIcon />
          </div>
          <h5 className="mt-5 mb-2">
            <CountUp end={data.pendingSales} className="counter-value" />
          </h5>
          <p className="text-slate-500 dark:text-slate-200">
            Receivables (AED)
          </p>
        </div>
      </div>

      {/* Total Employeyes */}
      <div className="order-3 md:col-span-6 lg:col-span-3 col-span-12 2xl:order-1 bg-purple-100 dark:bg-purple-500/20 card 2xl:col-span-2 group-data-[skin=bordered]:border-purple-500/20 relative overflow-hidden">
        <div className="card-body">
          <Kanban className="absolute top-0 size-32 stroke-1 text-purple-200/50 dark:text-purple-500/20 ltr:-right-10 rtl:-left-10">
          </Kanban>
          <div className="flex items-center justify-center size-12 bg-purple-500 rounded-md text-15 text-purple-50">
            <Users />
          </div>
          <h5 className="mt-5 mb-2">
            <CountUp end={data.totalEmployees} className="counter-value" />
          </h5>
          <p className="text-slate-500 dark:text-slate-200">
            HR
          </p>
        </div>
      </div>

      <div className="order-2 md:col-span-6 lg:col-span-3 col-span-12 2xl:order-1 bg-orange-100 dark:bg-orange-500/20 card 2xl:col-span-2 group-data-[skin=bordered]:border-purple-500/20 relative overflow-hidden">
        <div className="card-body">
          <Kanban className="absolute top-0 size-32 stroke-1 text-orange-200/50 dark:text-orange-500/20 ltr:-right-10 rtl:-left-10">
          </Kanban>
          <div className="flex items-center justify-center size-12 bg-orange-500 rounded-md text-15 text-orange-50">
            <ArrowUp className="" />
          </div>
          <h5 className="mt-5 mb-2">
            <CountUp
              end={data.totalSales - data.totalExpenses}
              className="counter-value"
            />
          </h5>
          <p className="text-slate-500 dark:text-slate-200">
            Profit (AED)
          </p>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Widgets;
