import Dashboard from "pages/Dashboard/Analytics";
import UsersTable from "pages/Users/ListView";
import Support from "pages/Support/Support";
import Deposit from "pages/Deposits/Deposit";
import UserProfile from "pages/UserProfile";
import Login from "pages/Authentication/Login";
import Logout from "pages/Authentication/LogOut";
import Sales from "pages/Sales/Sales";
import Renewals from "pages/Renewal/Renewal";
import Departments from "pages/Departments/Departments";
import Employees from "pages/Employees/Employees";
import SupportDetails from "pages/Support/SupportDetails";
import PettyCash from "pages/PettyCase";
import CarReport from "pages/Reports/CarReports";
import Reports from "pages/Reports/ExpenseReports";
import Purchases from "pages/Purchases/Purchases";
import PettyCashReport from "pages/Reports/PettyCashReport";
import Vouchers from "pages/Vouchers/Vouchers";

interface RouteObject {
  path: string;
  component: React.ComponentType<any>;
  exact?: boolean;
}

let authProtectedRoutes: Array<RouteObject> = [
  // Dashboard
  { path: "/", component: Dashboard },
  { path: "/dashboard", component: Dashboard },

  // Users
  { path: "/users", component: UsersTable },

  // Deposit
  { path: "/deposits/:id", component: Deposit },

  // Sales
  { path: "/sales", component: Sales },

  // Expenses
  { path: "/purchases", component: Purchases },

  // Renewal
  { path: "/renewals/:renewalType", component: Renewals },

  // Department
  { path: "/departments", component: Departments },

  // Department
  { path: "/employees", component: Employees },

  // Petty Cash
  {path:"/petty-cash", component: PettyCash},

  {path:"/petty-cash/report", component: PettyCashReport},

  // Reports
  { path: "/reports/sales", component: CarReport },
  { path: "/reports/purchases", component: Reports },

  //Vouchers
  { path: "/vouchers", component: Vouchers },

  // Support
  // { path: "/support", component: Support },
  // { path: "/support/:id", component: SupportDetails },

  // profile
  { path: "/user-profile", component: UserProfile },
];

const publicRoutes = [
  // authentication
  { path: "/login", component: Login },
  { path: "/logout", component: Logout },
];

export { authProtectedRoutes, publicRoutes };
