// @ts-nocheck
import React from "react";
import {
  Document,
  Page,
  PDFDownloadLink,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import { toReadableDate } from "helpers/utils";
import { expenseTypes } from "Common/constants/expense";
import { saveAs } from "file-saver";
import letterHead from "assets/images/letterHead.jpg";

// Create styles
const styles = StyleSheet.create({
  page: {
    width: "100%",
    // height: "3508px",
    borderRadius: 5,
  },
  section: {
    padding: 10,
    color: "black",
    width: "100%",
  },
});

const daysPassed = (date: Date) => {
  const today = new Date();
  const diffTime = Math.abs(today.getTime() - date.getTime());
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
};

// sum up amount field in all expenses
const sumAmount = (expenses: any) => {
  return expenses?.reduce((acc: any, item: any) => acc + item.amount, 0);
};

const sumSales = (sales: any) => {
  return sales?.reduce((acc: any, item: any) => acc + item.saleAmount, 0);
};

// check if a service in expenses is empty
const checkEmpty = (expenses: any, service: string) => {
  return expenses?.filter((item: any) => item.service === service).length === 0;
};

// Create Document Component
const CarReportPdf = ({ report }: { report: any }) => (
  <>
    <Document style={{ width: "100%" }} className="w-full">
      <Page
        size="A4"
        style={styles.page}
        className="flex flex-col w-full relative"
      >
        <img
          src={letterHead}
          className="w-full relative top-0"
        />

        {
          /* <View style={styles.section} className="flex flex-col gap-1">
          <View className="flex flex-row gap-4">
            <Text className="font-semibold">Make</Text>
            <Text>{report?.make}</Text>
          </View>
          <View className="flex flex-row gap-4">
            <Text className="font-semibold">Model</Text>
            <Text>{report?.model}</Text>
          </View>
          <View className="flex flex-row gap-4">
            <Text className="font-semibold">Creation Date</Text>
            <Text>{report ? toReadableDate(report?.createdAt) : ""}</Text>
          </View>
          <View className="flex flex-row gap-4">
            <Text className="font-semibold">Registration Valid Till</Text>
            <Text>{toReadableDate(report?.validRegistration)}</Text>
          </View>
          <View className="flex flex-row gap-4">
            <Text className="font-semibold">Vehicle Registration Number</Text>
            <Text>{report?.registrationNumber}</Text>
          </View>
          <View className="flex flex-col gap-1">
            <Text className="font-semibold">Assigned Drivers</Text>
            <Text>
              {report?.assignedDrivers?.map((
                driver: any,
                index: any,
              ) => (
                <View className="flex flex-row justify-between gap-5">
                  <Text>
                    {++index}. {driver?.user?.name}
                  </Text>
                </View>
              ))}
            </Text>
          </View>
        </View> */
        }

        <View className="flex p-5 flex-row z-10 absolute top-44 text-sm w-full">
          {
            /* <View
            style={styles.section}
            className="flex flex-col w-1/2"
          > */
          }
          {
            /* <View className="flex flex-col w-full gap-1 p-1">
              <View className="flex font-semibold flex-row justify-between">
                <Text>Particulars</Text>
                <Text>Amount AED</Text>
              </View>
              <View className=" flex flex-row items-center  pb-1 justify-between w-full">
                <Text className="w-1/3 font-semibold">UBER</Text>
                <View className="w-full">
                  {!checkEmpty(report?.expenses, "uber")
                    ? report?.expenses.filter((item) =>
                      item.service.toLowerCase() == "uber"
                    ).map((item, index) => (
                      <View className="flex flex-col border-l-custom-300 pl-2 gap-1 border-l w-full">
                        <View className="flex flex-row justify-between pb-1 ">
                          <Text className="">
                            {toReadableDate(item.expenseDate)}
                          </Text>
                          <Text className=" text-end">
                            {item.amount}
                          </Text>
                        </View>
                      </View>
                    ))
                    : (
                      <View className="flex flex-col border-l-custom-300 pl-2 gap-1 border-l w-full">
                        <View className="flex flex-row justify-between pb-1 ">
                          <Text className="w-full text-end">
                            N/A
                          </Text>
                        </View>
                      </View>
                    )}
                </View>
              </View>
              <View className=" flex flex-row items-center justify-between w-full">
                <Text className="w-1/3 font-semibold">
                  YANGO
                </Text>
                <View className="w-full">
                  {!checkEmpty(report?.expenses, "yango")
                    ? report?.expenses.filter((item) =>
                      item.service.toLowerCase() == "yango"
                    ).map((item, index) => (
                      <View className="flex flex-col border-l-custom-300 pl-2 gap-1 border-l w-full">
                        <View className="flex flex-row justify-between pb-1 ">
                          <Text className="">
                            {toReadableDate(item.expenseDate)}
                          </Text>
                          <Text className=" text-end">
                            {item.amount}
                          </Text>
                        </View>
                      </View>
                    ))
                    : (
                      <View className="flex flex-col border-l-custom-300 pl-2 gap-1 border-l w-full">
                        <View className="flex flex-row justify-between pb-1 ">
                          <Text className="w-full text-end">
                            N/A
                          </Text>
                        </View>
                      </View>
                    )}
                </View>
              </View>

              <View className=" flex flex-row items-center justify-between w-full">
                <Text className="w-1/3 font-semibold">CAREEM</Text>
                <View className="w-full">
                  {!checkEmpty(report?.expenses, "careem")
                    ? report?.expenses.filter((item) =>
                      item.service.toLowerCase() == "careem"
                    ).map((item, index) => (
                      <View className="flex flex-col border-l-custom-300 pl-2 gap-1 border-l w-full">
                        <View className="flex flex-row justify-between pb-1 ">
                          <Text className="">
                            {toReadableDate(item.expenseDate)}
                          </Text>
                          <Text className=" text-end">
                            {item.amount}
                          </Text>
                        </View>
                      </View>
                    ))
                    : (
                      <View className="flex flex-col border-l-custom-300 pl-2 gap-1 border-l w-full">
                        <View className="flex flex-row justify-between pb-1 ">
                          <Text className="w-full text-end">
                            N/A
                          </Text>
                        </View>
                      </View>
                    )}
                </View>
              </View>
              <View className="flex mt-2 bg-custom-100 rounded p-1 font-semibold items-center flex-row justify-between">
                <Text className="text-custom-600">Total</Text>
                <Text className="text-custom-600">
                  {report ? sumAmount(report?.expenses) : 0}
                </Text>
              </View>
            </View> */
          }
          {/* </View> */}

          <View className="flex flex-col  w-full">
            <View className="flex flex-col w-full gap-1 p-1">
              <View className="flex font-semibold flex-row justify-between">
                <Text className="w-1/4">Date</Text>
                <Text className="w-1/4">Driver</Text>
                <Text className="w-1/4">Method</Text>
                <Text className="w-1/4 text-right">Amount AED</Text>
              </View>
              <View className="flex flex-col justify-between">
                {report?.map((item) => (
                  <View className="flex flex-row justify-between w-full">
                    <Text className="w-1/4">
                      {toReadableDate(item.saleDate)}
                    </Text>
                    <Text className="w-1/4">{item.driver?.user?.name}</Text>
                    <Text className="w-1/4">{item.paymentMethod}</Text>
                    <Text className="w-1/4 text-right">
                      {item.saleAmount}
                    </Text>
                  </View>
                ))}
              </View>
              <View className="flex mt-2 bg-primary-green/30 rounded p-1 items-center font-semibold flex-row justify-between">
                <Text className="text-primary-green">Total</Text>
                <Text className="text-primary-green">
                  {report ? sumSales(report) : 0}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  </>
);

export default CarReportPdf;
