import React from "react";
import BreadCrumb from "Common/BreadCrumb";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.css";

const CustomTimePicker = ({index, time, setTime}: {index?:number; time: Date, setTime: any}) => {
    return (
        <React.Fragment>
            <div className=" w-full mx-auto shadow-none">
                <div className="card shadow-none">
                    <Flatpickr
                        options={{
                            enableTime: true,
                            noCalendar: true,
                            dateFormat: "H:i",
                        }}
                        onChange={(date) => typeof index === 'number' ? setTime(index, date[0]) : setTime(date[0])}
                        value={time}
                        placeholder="Select Time"
                        className="w-full shadow-none border form-input  border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                    />
                </div>
            </div>
        </React.Fragment>
    );
}

export default CustomTimePicker;
