import { type ClassValue, clsx } from "clsx"
import { twMerge } from "tailwind-merge"

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function toReadableDate(date: string | Date) {
  return new Date(date).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
  })
}

export function capitalizeFirstLetter(text: string | undefined) {
  return text?.charAt(0).toUpperCase() + text!.slice(1);
}

export function toStringTime(date: Date) {

  const hours = date?.getHours().toString().padStart(2, '0');
  const min = date?.getMinutes().toString().padStart(2, '0');
  const sec = date?.getSeconds().toString().padStart(2, '0');

  const timeString = `${hours}:${min}:${sec}`;

  return timeString;
}

export function capitalizeAndReplace(str: string) {
  return str.replace(/(?:^|-)\w/g, function (match: any) {
    return match.toUpperCase();
  }).replace(/-/g, ' ');
}

const singleDigits = ["", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine"];
const teens = ["", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"];
const tens = ["", "Ten", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"];
const thousands = ["", "Thousand", "Million", "Billion"];

function convertHundreds(num: number): string {
    let result = "";
    if (num > 99) {
        result += singleDigits[Math.floor(num / 100)] + " Hundred ";
        num %= 100;
    }
    if (num > 10 && num < 20) {
        result += teens[num - 10] + " ";
    } else {
        result += tens[Math.floor(num / 10)] + " ";
        result += singleDigits[num % 10] + " ";
    }
    return result.trim();
}

export function numberToWords(num: number): string {
    if (num === 0) return "Zero";
    let result = "";
    let thousandCounter = 0;
    while (num > 0) {
        if (num % 1000 !== 0) {
            result = convertHundreds(num % 1000) + " " + thousands[thousandCounter] + " " + result;
        }
        num = Math.floor(num / 1000);
        thousandCounter++;
    }
    return result.trim();
}