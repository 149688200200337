import React, { useEffect, useState } from "react";
import BreadCrumb from "Common/BreadCrumb";
// icons
import { Download, Filter, Loader } from "lucide-react";
import { ToastContainer } from "react-toastify";
import axios from "axios";
import moment from "moment";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import CarReportPdf from "components/ReportPdf/CarReportPdf";

const CarReport = () => {
  const [vehiclesList, setVehiclesList] = useState([]);
  const [dataList, setDataList] = useState<any>([]);
  const [data, setData] = useState<any>([]);
  const [drivers, setDrivers] = useState<any[]>([]);
  const [report, setReport] = useState<any[]>([]);
  const [originalReport, setOriginalReport] = useState<any>(null);
  const [selectedDriver, setSelectedDriver] = useState<string>("");
  const [filterToDate, setFilterToDate] = useState<string | null>(null);
  const [filterFromDate, setFilterFromDate] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const downloadPDF = () => {
    const input: HTMLElement | null = document.getElementById("report-pdf");
    const scale = 3; // Increase the scale
    html2canvas(input!, { scale }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");

      const pdfWidth = 210; // A4 width in mm
      const pdfHeight = 297; // A4 height in mm

      const aspectRatio = canvas.width / canvas.height;

      let width = pdfWidth;
      let height = pdfWidth / aspectRatio;

      if (height > pdfHeight) {
        height = pdfHeight;
        width = pdfHeight * aspectRatio;
      }

      const pdf = new jsPDF({
        orientation: height > width ? "portrait" : "landscape",
        unit: "mm",
        format: "a4",
      });
      const x = (pdfWidth - width) / 2;
      const y = (pdfHeight - height) / 2;

      pdf.addImage(imgData, "PNG", 0, 0, width, height);
      pdf.save("download.pdf");
    });
  };

  function filterDataForLastMonth(report: any) {
    const currentDate = new Date();
    const lastMonthDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() - 1,
      currentDate.getDate(),
    ); // Calculate date for one month ago

    let filteredReport = originalReport; // Create a copy of the original report to avoid mutating it

    // Filter expenses based on expenseDate
    filteredReport = originalReport.filter(
      (expense: any) => new Date(expense.createdAt) > lastMonthDate,
    );

    return filteredReport;
  }

  function filterDataForLastYear(report: any) {
    const currentDate = new Date();
    const lastYearDate = new Date(
      currentDate.getFullYear() - 1,
      currentDate.getMonth(),
      currentDate.getDate(),
    ); // Calculate date for one year ago

    let filteredReport = originalReport; // Create a copy of the original report to avoid mutating it

    // Filter expenses based on expenseDate
    filteredReport = originalReport.filter(
      (expense: any) => new Date(expense.createdAt) > lastYearDate,
    );

    return filteredReport;
  }

  function filterDataForToday(report: any) {
    const currentDate = new Date();
    const todayDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate(),
    ); // Calculate date for today

    let filteredReport = originalReport; // Create a copy of the original report to avoid mutating it

    // Filter expenses based on expenseDate
    filteredReport = originalReport.filter(
      (expense: any) => new Date(expense.createdAt) > todayDate,
    );

    return filteredReport;
  }

  function filterDataForLastWeek(report: any) {
    const currentDate = new Date();
    const lastWeekDate = new Date(
      currentDate.getTime() - 7 * 24 * 60 * 60 * 1000,
    ); // Calculate date for one week ago

    let filteredReport = originalReport; // Create a copy of the original report to avoid mutating it

    // Filter expenses based on expenseDate
    filteredReport = originalReport.filter(
      (expense: any) => new Date(expense.createdAt) > lastWeekDate,
    );

    return filteredReport;
  }

  const customFilterData = (
    {
      fromDate,
      toDate,
      driverId,
    }: {
      fromDate: string | null;
      toDate: string | null;
      driverId: string | null;
    },
  ) => {
    let filteredReport = originalReport;
    if (fromDate) {
      filteredReport = filteredReport.filter(
        (sale: any) => new Date(sale.saleDate) > new Date(fromDate),
      );
    }
    if (toDate) {
      filteredReport = filteredReport.filter(
        (sale: any) => new Date(sale.saleDate) < new Date(toDate),
      );
    }
    if (driverId && driverId != "") {
      filteredReport = filteredReport.filter(
        (sale: any) => sale?.driver?._id === driverId,
      );
    }
    setReport(filteredReport);
  };

  const handleFetchDrivers = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URI}/crm/driver/all`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        },
      );
      setDrivers(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleFetchVehicles = async () => {
    try {
      setLoading(true);
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URI}/crm/sale/all`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        },
      );
      console.log(res.data);
      setReport(res.data);
      setReport((prev: any) => {
        return prev.map((item: any) => {
          return {
            ...item,
            createdAt: moment(item.createdAt).format("DD MMMM, YYYY"),
          };
        });
      });
      setOriginalReport(res.data);
    } catch (error) {
      console.log(error);
    }
    finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleFetchVehicles();
    handleFetchDrivers();
  }, []);

  useEffect(() => {
    setDataList(data);
  }, []);

  useEffect(() => {
    customFilterData({
      fromDate: filterFromDate,
      toDate: filterToDate,
      driverId: selectedDriver,
    });
  }, [filterFromDate, filterToDate, selectedDriver]);

  const clearFilters = () => {
    setFilterFromDate(null);
    setFilterToDate(null);
    setSelectedDriver("");
    setReport(originalReport);
  };

  return (
    <React.Fragment>
      {loading && (
        <div className="fixed inset-0 z-50 flex items-center justify-center w-full h-full bg-white bg-opacity-90 dark:bg-zink-900 dark:bg-opacity-90">
          <Loader className="size-10 text-custom-500 animate-spin" />
        </div>
      )}
      <BreadCrumb title="Sale Reports" pageTitle="Management" />
      <ToastContainer closeButton={false} limit={1} />
      <div className="grid grid-cols-1 gap-x-5 xl:grid-cols-12">
        <div className="xl:col-span-12">
          <div className="card" id="Drivers">
            <div className="card-body">
              <div className="flex flex-row items-center justify-between">
                <div className="flex items-center">
                  <h6 className="text-15 grow">Sale Report</h6>
                </div>
                <select
                  disabled={report ? false : true}
                  className="w-fit form-select text-slate-400 border-slate-200 dark:border-zink-500 dark:text-zink-200"
                  onChange={(e) => {
                    const value = e.target.value;
                    // Call a function to determine the new state value
                    if (value == "day") {
                      setReport(filterDataForToday(report));
                    } else if (value == "week") {
                      setReport(filterDataForLastWeek(report));
                    } else if (value == "month") {
                      setReport(filterDataForLastMonth(report));
                    } else if (value == "year") {
                      setReport(filterDataForLastYear(report));
                    } else {
                      setReport(originalReport);
                    }
                  }}
                >
                  <option value="all" selected>All time</option>
                  <option value="day">Today</option>
                  <option value="week">Last Week</option>
                  <option value="month">Last Month</option>
                  <option value="year">Last Year</option>
                </select>
              </div>
              <div className=" w-full flex lg:flex-row flex-col gap-5 mt-2">
                <div className="lg:w-2/3 w-full" id="report-pdf">
                  <CarReportPdf report={report} />
                </div>
                <div className="lg:w-1/3 w-full">
                  {
                    /*
                  <h5>Select Driver</h5>
                  <select
                    onChange={(e) => {
                      getVehicleReport(e.target.value);
                      setSelectedDriver(true);
                    }}
                    className="border w-full dark:bg-transparent mt-2 p-1 rounded-md text-lg px-2"
                  >
                    <option value="" disabled selected>Select a vehicle</option>
                    {vehiclesList.map((item: any) => {
                      return (
                        <option value={item._id}>
                          {item.make}, {item.model}
                        </option>
                      );
                    })}
                  </select>
                  */
                  }
                  <div className=" text-slate-400 flex flex-col mt-5 gap-4 w-full">
                    <div className="w-full flex flex-row gap-2 items-center">
                      <label
                        htmlFor="fromDate"
                        className="w-1/4 inline-block mb-2 text-base font-medium"
                      >
                        From
                      </label>
                      <input
                        type="date"
                        id="fromDate"
                        className="form-input border-slate-200 w-full dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                        placeholder="12345678"
                        name="fromDate"
                        onChange={(e) =>
                          // customFilterData({ fromDate: e.target.value })
                          setFilterFromDate(e.target.value)}
                        value={filterFromDate || ""}
                      />
                    </div>

                    <div className="w-full flex flex-row gap-2 items-center">
                      <label
                        htmlFor="toDate"
                        className="w-1/4 inline-block mb-2 text-base font-medium"
                      >
                        To
                      </label>
                      <input
                        type="date"
                        id="toDate"
                        className="form-input w-full border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                        placeholder="12345678"
                        name="toDate"
                        onChange={(e) =>
                          // customFilterData({ toDate: e.target.value })
                          setFilterToDate(e.target.value)}
                        // onChange={filterDate.handleChange}
                        value={filterToDate || ""}
                      />
                    </div>
                    <div className="w-full flex flex-row gap-2 items-center">
                      <label
                        htmlFor="toDate"
                        className="w-1/4 inline-block mb-2 text-base font-medium"
                      >
                        Driver
                      </label>
                      <select
                        className="border w-full p-2 rounded-md"
                        value={selectedDriver}
                        onChange={(e) => setSelectedDriver(e.target.value)}
                      >
                        <option value="" disabled selected>
                          Select a driver
                        </option>
                        {drivers?.map((item: any) => {
                          return (
                            <option value={item._id}>{item.user.name}</option>
                          );
                        })}
                      </select>
                    </div>

                    {
                      /* <button
                      onClick={(e) => {
                        e.preventDefault();
                        // handleFilterDate();
                      }}
                      className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
                    >
                      {"Filter "}
                    </button> */
                    }

                    <button
                      type="button"
                      className={`${selectedDriver || filterFromDate || filterToDate
                        ? ""
                        : "hidden"
                        } bg-white mt-4 border w-full text-custom-500 btn border-custom-500 hover:text-custom-500 hover:bg-custom-50 hover:border-custom-600 focus:text-custom-600 focus:bg-custom-50 focus:border-custom-600 active:text-custom-600 active:bg-custom-50 active:border-custom-600 dark:bg-zink-700 dark:ring-custom-400/20 dark:hover:bg-custom-800/20 dark:focus:bg-custom-800/20 dark:active:bg-custom-800/20`}
                      onClick={clearFilters}
                    >
                      <Filter className="size-4 inline-block mr-1" />
                      <span className="align-middle">Clear filters</span>
                    </button>
                  </div>

                  <button
                    type="button"
                    className="bg-white mt-4 border-dashed w-full text-custom-500 btn border-custom-500 hover:text-custom-500 hover:bg-custom-50 hover:border-custom-600 focus:text-custom-600 focus:bg-custom-50 focus:border-custom-600 active:text-custom-600 active:bg-custom-50 active:border-custom-600 dark:bg-zink-700 dark:ring-custom-400/20 dark:hover:bg-custom-800/20 dark:focus:bg-custom-800/20 dark:active:bg-custom-800/20"
                    onClick={downloadPDF}
                    disabled={report && report.length > 0 ? false : true}
                  >
                    <Download className="inline-block size-4" />{" "}
                    <span className="align-middle">Export</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CarReport;
