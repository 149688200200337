import React from 'react';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import moment from 'moment';
import { auto } from '@popperjs/core';
import { numberToWords } from 'helpers/utils';

const styles = StyleSheet.create({
    page: {
        display: "flex",
        flexDirection: "column",
        padding: 30,
        fontFamily: "Times-Roman",
    },
    header: {
        display: "flex",
        flexDirection: "row",
        gap: "35px",
        width: "100%",
    },
    section: {
        margin: 10,
        padding: 10,
        backgroundColor: "transparent"
    },
    title: {
        fontSize: 11,
        textAlign: 'center',
        fontFamily: "Times-Bold",
        fontWeight: 600,
    },
    coTitle: {
        fontSize: 13,
        textAlign: 'center',
        fontWeight: 300,
        textTransform: 'uppercase',
        paddingBottom: 3,
        paddingRight: 15,
        borderBottom: "1px dashed black"
    },
    contactWrapper: {
        marginTop: 10,
        display: "flex",
        flexDirection: "column",
        gap: "5px",
    },
    contactInfo: {
        display: "flex",
        flexDirection: "row",
        backgroundColor: "transparent",
        gap: "0px",
        width: "100%",
    },
    contactTitle: {
        width: "13%",
        fontWeight: 600,
        fontFamily: "Times-Bold",
        fontSize: 11,
    },
    contactBody: {
        flex: 1,
        fontSize: 11,
    },
    propertyWrapper: {
        marginTop: 10,
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        marginBottom: 50,
    },
    propertyTitle: {
        width: "17%",
        fontFamily: "Times-Bold",
        fontWeight: 600,
        fontSize: 11,
    },
    propertyBody: {
        flex: 1,
        fontSize: 11,
        flexWrap: "wrap",
        borderBottom: "1px dashed black"
    },
    feeNumeric: {
        flex: 1,
        fontSize: 12,
        fontFamily: "Times-Bold",
        fontWeight: 600,
        borderBottom: "1px dashed black"
    },
    voucherTypeWrapper: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
    },
    voucherType: {
        fontSize: 16,
        fontWeight: 600,
        fontFamily: "Times-Bold",
        color: "blue",
        textDecoration: "underline"
    },
    dateWrapper: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        fontWeight: 600,
        gap: "10px",
        fontFamily: "Times-Bold",
        width: "30%",
        fontSize: 11,
    },
    redText: {
        fontFamily: "Times-Bold",
        fontWeight: 600,
        color: "red",
        fontSize: 11,
    },
    dateBody: {
        borderBottom: "1px dashed black",
        flex: 1,
    },
    flex_row: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        justifyContent: "center",
    },
    bottomText: {
        textAlign: "center",
        textDecoration: "underline",
        marginTop: 30,
        fontSize: 11,
        fontWeight: 600,
        fontFamily: "Times-Bold",
    },
    financeText: {
        width: "50%",
        textDecoration: "underline",
        fontWeight: 600,
        fontFamily: "Times-Bold",
        fontSize: 11,
        textAlign: "center",
    },
    feeEnglish: {
        width: "100%",
        borderBottom: "1px dashed black",
        fontSize: 12,
        fontFamily: "Times-Italic",
        fontWeight: 600,
        textAlign: "center",
    },
});

interface VoucherProps {
    voucherData: {
        date: string;
        paidTo: string;
        sumOfDhs: string;
        settlementOf: string;
        voucherCount: number;
        by: string;
        voucherType: "Payment" | "Receipt";
    }
}
const VoucherPdf = ({ voucherData }: VoucherProps) => {

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.section}>
                    <View style={styles.header}>
                        <Text style={styles.title}>Co. Name:</Text>
                        <Text style={styles.coTitle}>SZL Luxury Motor Vehicles Services L.L.C</Text>
                    </View>
                    <View style={styles.contactWrapper}>
                        <View style={styles.contactInfo}>
                            <Text style={styles.contactTitle}>Tell:</Text>
                            <Text style={styles.contactBody}>+971 4 2252401</Text>
                        </View>
                        <View style={styles.contactInfo}>
                            <Text style={styles.contactTitle}>Fax:</Text>
                            <Text style={styles.contactBody}>+971 4 2252403</Text>
                        </View>
                        <View style={styles.contactInfo}>
                            <Text style={styles.contactTitle}>P.O Box:</Text>
                            <Text style={styles.contactBody}>371363</Text>
                        </View>
                        <View style={styles.voucherTypeWrapper}>
                            <Text style={styles.contactBody}>Dubai, United Arab Emirates</Text>
                            <Text style={styles.voucherType}>{voucherData.voucherType} Voucher</Text>
                        </View>
                    </View>
                </View>
                <View style={styles.section}>
                    <View style={styles.voucherTypeWrapper}>
                        <View>
                            <Text style={styles.redText}>No.: {voucherData.voucherCount}</Text>
                        </View>
                        <View style={styles.dateWrapper}>
                            <Text>
                                Date:
                            </Text>
                            <Text style={styles.dateBody}>
                                {moment(voucherData.date).format('DD-MMM-YYYY')}
                            </Text>
                        </View>
                    </View>
                </View>
                <View style={styles.section}>
                    <View style={styles.propertyWrapper}>
                        <View style={styles.contactInfo}>
                            <Text style={styles.propertyTitle}>{voucherData.voucherType === 'Payment' ? "Paid To:" : "Received By:"}</Text>
                            <Text style={styles.propertyBody}>{voucherData.paidTo}</Text>
                        </View>
                        <View style={styles.contactInfo}>
                            <Text style={styles.propertyTitle}>The Sum of Dhs:</Text>
                            <Text style={styles.feeNumeric}>{Number(voucherData.sumOfDhs).toFixed(2)}</Text>
                        </View>
                        <Text style={styles.feeEnglish}>
                            {numberToWords(Number(voucherData.sumOfDhs))} fils Only.
                        </Text>
                        <View style={styles.contactInfo}>
                            <Text style={styles.propertyTitle}>In Settlement Of:</Text>
                            <Text style={styles.propertyBody}>{voucherData.settlementOf}</Text>
                        </View>
                        <View style={styles.contactInfo}>
                            <Text style={styles.propertyTitle}>By:</Text>
                            <Text style={styles.propertyBody}>{voucherData.by}</Text>
                        </View>
                    </View>
                </View>
                <View>
                    <View style={styles.flex_row}>
                        <View style={styles.financeText}>
                            <Text>Accountant</Text>
                        </View>
                        <View style={styles.financeText}>
                            <Text>Cashier</Text>
                        </View>
                    </View>
                    <View style={styles.flex_row}>
                        <View style={styles.bottomText}>
                            <Text>Approved By:</Text>
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    );
};

export default VoucherPdf;